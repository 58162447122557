import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type CompanyModel from './company.ts';
import type TasProgramTemplateModel from './tas-program-template.ts';
import type TasGroupingModel from './tas-grouping.ts';

export default class TasGroupModel extends Model {
  @attr declare flavor: string;

  /*************************
   **  Relationships      **
   *************************/

  @hasMany('tas-grouping', { async: false, inverse: 'tasGroup' })
  declare tasGroupings: TasGroupingModel[];
  @belongsTo('tas-program-template', { async: false, inverse: 'tasGroups' })
  declare tasProgramTemplate: TasProgramTemplateModel[];
  @belongsTo('company', { async: false, inverse: 'tasGroups' })
  declare company: CompanyModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-group': TasGroupModel;
  }
}
