import { object, string, number } from 'yup';

const newAccountingLineItemSchema = object({
  amount: number().moreThan(0, 'amount must be more than $0').required(),
  description: string(),
  employeeId: string().required('employee is required'),
  invoiceId: string().required('invoice is required'),
  period: string()
    .required()
    .test({
      name: 'is-valid-period',
      skipAbsent: true,
      test(value, context) {
        const { options } = context;
        if (!options.context?.validPeriods.includes(value)) {
          return context.createError({
            message: 'please select a valid period',
          });
        }
        return true;
      },
    }),
  type: string()
    .required()
    .oneOf(['BACKPAY', 'ADHOC_CONTRIBUTION'])
    .test({
      name: 'is-valid-type',
      skipAbsent: true,
      test(value, context) {
        const { options, parent } = context;
        if (value === 'BACKPAY' && !options.context?.canCreateBackpay && parent.employeeId) {
          return context.createError({
            message: 'backpay is not available for selected employee',
          });
        }
        return true;
      },
    }),
});

export default newAccountingLineItemSchema;
