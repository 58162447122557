import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type AgreementModel from './agreement.ts';
import type AttachmentModel from './attachment.ts';
import type PublicAssetModel from './public-asset.ts';

// Right now we don't support other provinces/classifications but we likely will in the future.
export type ConditionClassification =
  | 'app_terms_of_use'
  | 'app_privacy_policy'
  | 'custom'
  | ''
  | undefined;
export type ConditionProvince =
  | 'register'
  | 'application_approve_courses_workflow'
  | 'application_approve_evidence_workflow'
  | 'program_approve_withdraw_complete_workflow'
  | 'program_approve_workflow'
  | ''
  | undefined;

export default class ConditionModel extends Model {
  @attr declare classification: ConditionClassification;
  @attr declare province: ConditionProvince;
  @attr declare expiryCadence: string;
  @attr declare expiryFixedDate: string;
  @attr declare filename: string;
  @attr declare fullText: string;
  @attr declare prompt: string;
  @attr declare isDefault: boolean;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  get staticJsonAssetName() {
    if (!this.filename) {
      return '';
    }
    return this.filename.split('.json')[0] || '';
  }

  get hasPdf() {
    return !!this.publicAssets?.length;
  }

  get pdfUrl() {
    return this.publicAssets?.[0]?.signedUrl || '';
  }

  /*************************
   **  Relationships      **
   *************************/

  @hasMany('agreement', { async: false, inverse: 'condition' })
  declare agreements: AgreementModel[];
  @belongsTo('conditionable', {
    async: false,
    polymorphic: true,
    inverse: 'conditions',
  })
  declare conditionable: Record<string, unknown>;
  @hasMany('public-asset', { async: false, inverse: 'conditions' })
  declare publicAssets: PublicAssetModel[];
  @hasMany('attachment', { async: false, inverse: null })
  declare attachments: AttachmentModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    condition: ConditionModel;
  }
}
