import Model, { attr, belongsTo } from '@ember-data/model';
import type AggregationAccountModel from './aggregation-account.ts';

export default class AggregationAccountChangeLogModel extends Model {
  @attr declare objectChanges: Record<string, unknown>;
  @attr declare at: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('aggregation-account', { async: false, inverse: null })
  declare aggregationAccount: AggregationAccountModel;

  /**************************
   **  Computed Properties **
   **************************/
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'aggregation-account-change-log': AggregationAccountChangeLogModel;
  }
}
