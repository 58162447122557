import { action } from '@ember/object';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import {
  setSingleValueForTasField as setValueForField,
  getSingleValueForTasField,
} from '../utils/tuition-assistance/fields.ts';
import CommentableModel from './commentable.ts';
import type EmployeeModel from './employee.ts';
import type TASProgramTemplateModel from './tas-program-template.ts';
import type TASApplicationModel from './tas-application.ts';
import type TASAssetModel from './tas-asset.ts';
import type AttachmentModel from './attachment.ts';
import type {
  CustomFieldSignature,
  TransitionLogSignature,
  StringSingleValueField,
  ApplicationApprovalSummary,
  ApplicationApprovalSummaryApprover,
  TasProgramInstanceState,
  TasAppHistory,
} from '../types/tuition-assistance.ts';
import type DependentModel from './dependent.ts';

export type TASProgramInstanceModelFieldsSignature = {
  DAPIP_INSTITUTION_ID?: StringSingleValueField;
  TAS_INSTITUTION_ID?: StringSingleValueField;
  ESTIMATED_PROGRAM_BEGIN?: StringSingleValueField;
  ESTIMATED_PROGRAM_COMPLETION?: StringSingleValueField;
  PROGRAM_SPECIALIZATION?: StringSingleValueField;
  PROGRAM_MAJOR?: StringSingleValueField;
  SCHOOL_INSTITUTION_NAME?: StringSingleValueField;
  FT_PT_OTHER: StringSingleValueField;
  HIRE_DATE: StringSingleValueField;
  NAME: StringSingleValueField;
  EMPLOYEE_ID: StringSingleValueField;
  EMPLOYEE_TITLE: StringSingleValueField;
  EMPLOYEE_EMAIL: StringSingleValueField;
  DEPENDENT_NAME?: StringSingleValueField;
  DEPENDENT_DOB?: StringSingleValueField;
  DEPENDENT_ID?: StringSingleValueField;
  DEPENDENT_RELATIONSHIP?: StringSingleValueField;
  ESTIMATED_COST?: StringSingleValueField;
  ENROLLMENT_OBJECTIVE?: StringSingleValueField;
  // TODO: Complete this
};

export default class TASProgramInstanceModel extends CommentableModel {
  @attr declare state: TasProgramInstanceState;
  @attr declare fields: TASProgramInstanceModelFieldsSignature;
  @attr declare customFields: CustomFieldSignature[];
  @attr declare createdAt: string;
  @attr declare updatedAt: string;
  @attr declare transitionLog: TransitionLogSignature[];
  @attr declare migrationApplicationId: string;
  @attr declare programApproval: ApplicationApprovalSummary;
  @attr declare completionApproval: ApplicationApprovalSummary;
  @attr declare withdrawalApproval: ApplicationApprovalSummary;
  @attr declare isTrial: boolean;
  @attr declare history: TasAppHistory[];

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('employee', { async: false, inverse: 'tasProgramInstances' })
  declare employee: EmployeeModel;
  @belongsTo('tas-program-template', {
    async: false,
    inverse: 'tasProgramInstances',
  })
  declare tasProgramTemplate: TASProgramTemplateModel;
  @belongsTo('dependent', { async: false, inverse: 'tasProgramInstances' })
  declare dependent: DependentModel;
  @hasMany('tas-application', { async: false, inverse: 'tasProgramInstance' })
  declare tasApplications: TASApplicationModel[];
  @hasMany('tas-asset', { async: false, inverse: 'tasProgramInstances' })
  declare tasAssets: TASAssetModel[];
  @hasMany('attachment', { async: false, inverse: null })
  declare attachments: AttachmentModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get programName() {
    return this.tasProgramTemplate?.programName || '';
  }

  get adminDisplayName() {
    return this.programName;
  }

  get latestStateChangeLabel() {
    const latestTransition =
      this.transitionLog?.length > 0 ? this.transitionLog[this.transitionLog.length - 1] : null;
    return latestTransition?.to || '';
  }

  get latestStateChangeDate() {
    const latestTransition =
      this.transitionLog?.length > 0 ? this.transitionLog[this.transitionLog.length - 1] : null;
    return latestTransition?.timestamp || '';
  }

  get latestStateChangePerson() {
    const latestTransition =
      this.transitionLog?.length > 0 ? this.transitionLog[this.transitionLog.length - 1] : null;
    return latestTransition?.reason?.by?.name || '';
  }

  get isDraft() {
    return this.state === 'TAS.ProgramInstanceState.DRAFT';
  }

  get employmentType() {
    return getSingleValueForTasField('FT_PT_OTHER', this.fields) || this.employee?.employmentType;
  }

  get estimatedProgramBegin() {
    return this.fields?.['ESTIMATED_PROGRAM_BEGIN']?.values?.[0] || '';
  }

  get estimatedProgramCompletion() {
    return this.fields?.['ESTIMATED_PROGRAM_COMPLETION']?.values?.[0] || '';
  }

  get programSpecialization() {
    return this.fields?.['PROGRAM_SPECIALIZATION']?.values?.[0] || '';
  }

  get institutionName() {
    return this.fields?.['SCHOOL_INSTITUTION_NAME']?.values?.[0] || '';
  }

  get programMajor() {
    return this.fields?.['PROGRAM_MAJOR']?.values?.[0] || '';
  }

  get canMarkComplete() {
    return ![
      'TAS.ProgramInstanceState.PENDING_WITHDRAWAL_APPROVAL',
      'TAS.ProgramInstanceState.WITHDRAWAL_APPROVED',
      'TAS.ProgramInstanceState.WITHDRAWN',
      'TAS.ProgramInstanceState.COMPLETED',
      'TAS.ProgramInstanceState.COMPLETION_APPROVED',
      'TAS.ProgramInstanceState.PENDING_COMPLETION_APPROVAL',
    ].includes(this.state);
  }

  get canWithdraw() {
    return this.canMarkComplete;
  }

  get certificateApplicationInDraft() {
    const isCertificateProgram = this.tasProgramTemplate.isCertificateProgram;
    return (
      isCertificateProgram &&
      (!this.tasApplications?.length ||
        this.tasApplications[0]?.isDraft ||
        this.tasApplications[0]?.isNew)
    );
  }

  get certificateInstanceInDraft() {
    const isCertificateProgram = this.tasProgramTemplate.isCertificateProgram;
    const isDraftOrConsideredDraft = this.certificateStatesConsideredDraft.has(this.state);
    const isPrimedWithNoApplications =
      this.state === 'TAS.ProgramInstanceState.PRIMED' && !this.tasApplications?.length;

    return isCertificateProgram && (isDraftOrConsideredDraft || isPrimedWithNoApplications);
  }

  get modelName() {
    return 'tas-program-instance';
  }

  get currentExpectedApproverEmployeeIds() {
    let approvalConfigType = 'programApproval';

    if (this.state === 'TAS.ProgramInstanceState.PENDING_COMPLETION_APPROVAL') {
      approvalConfigType = 'completionApproval';
    }

    if (this.state === 'TAS.ProgramInstanceState.PENDING_WITHDRAWAL_APPROVAL') {
      approvalConfigType = 'withdrawalApproval';
    }

    const reviewers: ApplicationApprovalSummaryApprover[] =
      this[approvalConfigType]?.current_reviewers || [];
    return new Set(reviewers.map((reviewer) => reviewer['employee_id']));
  }

  get programInstanceInProgress() {
    return this.submittedActiveInstanceStates.has(this.state);
  }

  get approverCanEditApplication() {
    return this.submittedActiveInstanceStates.has(this.state);
  }

  get isDependentProgram() {
    return this.tasProgramTemplate.isDependentProgram;
  }

  get dependentName() {
    return this.fields?.['DEPENDENT_NAME']?.values?.[0] || '';
  }

  get inRescindableState() {
    return this.rescindableStates.has(this.state);
  }

  get isCompleted() {
    return this.completedStates.has(this.state);
  }

  get programSubmissionDate() {
    const timestamp = this.transitionLog.find(
      (log) => log.event === 'request_program_approval'
    )?.timestamp;
    return timestamp ? new Date(timestamp).toLocaleDateString() : '';
  }

  @action
  async loadHistory(): Promise<void | TasAppHistory[]> {
    const history = await this.store.adapterFor('tas-program-instance').getHistory(this);
    this.history = history.data;
  }

  setInitialEmployeeFields() {
    const employee = this.employee;
    if (!employee) {
      throw 'Employee must exist to define initial program fields';
    }
    if (!this.fields) {
      this.fields = {} as TASProgramInstanceModelFieldsSignature;
    }

    setValueForField('NAME', employee.fullName, this.fields);
    setValueForField('EMPLOYEE_ID', employee.payrollId, this.fields);
    setValueForField('EMPLOYEE_TITLE', employee.title, this.fields);
    setValueForField('FT_PT_OTHER', employee.employmentType, this.fields);
    setValueForField('HIRE_DATE', employee.employmentStartDate, this.fields);
    setValueForField('EMPLOYEE_EMAIL', employee.email, this.fields);
  }

  setInitialDependentFields() {
    const dependent = this.dependent;
    if (!dependent) {
      throw 'A dependent must exist to define initial program fields';
    }
    if (!this.fields) {
      this.fields = {} as TASProgramInstanceModelFieldsSignature;
    }
    setValueForField('DEPENDENT_NAME', dependent.fullName, this.fields);
    setValueForField('DEPENDENT_DOB', dependent.birthDate, this.fields);
    setValueForField('DEPENDENT_ID', dependent.id, this.fields);
    setValueForField('DEPENDENT_RELATIONSHIP', dependent.relationship, this.fields);
  }

  certificateStatesConsideredDraft: Set<TasProgramInstanceState> = new Set([
    'TAS.ProgramInstanceState.CREATED',
    'TAS.ProgramInstanceState.DRAFT',
    'TAS.ProgramInstanceState.PENDING_PROGRAM_APPROVAL',
    'TAS.ProgramInstanceState.PROGRAM_APPROVED',
  ]);

  submittedActiveInstanceStates: Set<TasProgramInstanceState> = new Set([
    'TAS.ProgramInstanceState.PENDING_PROGRAM_APPROVAL',
    'TAS.ProgramInstanceState.PROGRAM_APPROVED',
    'TAS.ProgramInstanceState.PROGRAM_NOT_APPROVED',
    'TAS.ProgramInstanceState.PRIMED',
    'TAS.ProgramInstanceState.PENDING_COMPLETION_APPROVAL',
    'TAS.ProgramInstanceState.COMPLETION_APPROVED',
    'TAS.ProgramInstanceState.COMPLETION_NOT_APPROVED',
    'TAS.ProgramInstanceState.PENDING_WITHDRAWAL_APPROVAL',
    'TAS.ProgramInstanceState.WITHDRAWAL_APPROVED',
    'TAS.ProgramInstanceState.WITHDRAWAL_NOT_APPROVED',
  ]);

  rescindableStates: Set<TasProgramInstanceState> = new Set([
    'TAS.ProgramInstanceState.DRAFT',
    'TAS.ProgramInstanceState.PENDING_PROGRAM_APPROVAL',
    'TAS.ProgramInstanceState.PRIMED',
    'TAS.ProgramInstanceState.PENDING_COMPLETION_APPROVAL',
    'TAS.ProgramInstanceState.PENDING_WITHDRAWAL_APPROVAL',
    'TAS.ProgramInstanceState.COMPLETED',
    'TAS.ProgramInstanceState.WITHDRAWN',
  ]);

  completedStates: Set<TasProgramInstanceState> = new Set([
    'TAS.ProgramInstanceState.COMPLETED',
    'TAS.ProgramInstanceState.WITHDRAWN',
    'TAS.ProgramInstanceState.ABANDONED',
  ]);
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-program-instance': TASProgramInstanceModel;
  }
}
