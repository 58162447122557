import Model, { attr, belongsTo } from '@ember-data/model';
import type OwnerModel from './owner.ts';

export default class ScheduleModel extends Model {
  @attr declare ownerId: string;
  @attr declare ownerType: string;
  @attr declare scheduleType: string;
  @attr declare description: string;
  @attr declare startDate: string;
  @attr declare endDate: string;
  @attr declare intervalUnit: string;
  @attr declare intervalValue: number;
  @attr declare dateUnit: string;
  @attr declare dateValues: string[];
  @attr declare config: Record<string, unknown>;
  @attr declare isActive: boolean;
  @attr declare nextEventDate: string;
  @attr declare dayOfWeek: string;

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('owner', {
    async: false,
    polymorphic: true,
    inverse: 'schedules',
  })
  declare owner: OwnerModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    schedule: ScheduleModel;
  }
}
