import Model, { attr } from '@ember-data/model';

export default class PslfGroupModel extends Model {
  @attr declare approverEmail: string;
  @attr declare approverName: string;
  @attr declare approverPhoneNumber: string;
  @attr declare approverTitle: string;
  @attr declare approverVerifyWithEmail: boolean;
  @attr declare createdBy: Record<string, unknown>;
  @attr declare createdOn: string;
  @attr declare deletedAt: string;
  @attr declare employerAddressLine1: string;
  @attr declare employerAddressLine2: string;
  @attr declare employerCity: string;
  @attr declare employerCommonName: string;
  @attr declare employerFein: string;
  @attr declare employerIsGovernment: boolean;
  @attr declare employerIsNotForProfit: boolean;
  @attr declare employerIsPolitical: boolean;
  @attr declare employerIsTaxExempt: boolean;
  @attr declare employerLegalName: string;
  @attr declare employerPostalCode: string;
  @attr declare employerServices: Record<string, unknown>;
  @attr declare employerState: string;
  @attr declare employerWebsite: string;
  @attr declare updatedBy: Record<string, unknown>;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;
  @attr declare legacyId: string;
  @attr declare preApprovedTemplateId: string;
  @attr declare pslfSettingId: number;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'pslf-group': PslfGroupModel;
  }
}
