import type { TransitionLogSignature } from '../../types/tuition-assistance.ts';
import type { ApprovedTotalLogSignature } from '../../models/tas-application.ts';

const applicationRestingStates = [
  'TAS.ApplicationState.ABANDONED',
  'TAS.ApplicationState.FULFILLED',
  'TAS.ApplicationState.EVIDENCE_REJECTED',
  'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
  'TAS.ApplicationState.ATTEND',
  'TAS.ApplicationState.COURSES_REJECTED',
  'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
  'TAS.ApplicationState.DEFINE_COURSES',
];

const programRestingStates = [
  'TAS.ProgramInstanceState.ABANDONED',
  'TAS.ProgramInstanceState.COMPLETED',
  'TAS.ProgramInstanceState.COMPLETION_REJECTED',
  'TAS.ProgramInstanceState.DRAFT',
  'TAS.ProgramInstanceState.PENDING_COMPLETION_APPROVAL',
  'TAS.ProgramInstanceState.PENDING_PROGRAM_APPROVAL',
  'TAS.ProgramInstanceState.PENDING_WITHDRAWAL_APPROVAL',
  'TAS.ProgramInstanceState.PRIMED',
  'TAS.ProgramInstanceState.PROGRAM_REJECTED',
  'TAS.ProgramInstanceState.WITHDRAWAL_REJECTED',
  'TAS.ProgramInstanceState.WITHDRAWN',
];

export function filterTransitionLogs(logs: TransitionLogSignature[], modelType: string) {
  if (!logs) {
    return [];
  }
  const userInitiatedEvent = (log: TransitionLogSignature) => {
    return log.reason && log.reason.by;
  };

  const filterCondition = (log: TransitionLogSignature) => {
    switch (modelType) {
      case 'ProgramInstanceState':
        return programRestingStates.includes(log.to) || userInitiatedEvent(log);
      case 'ApplicationState':
        return applicationRestingStates.includes(log.to) || userInitiatedEvent(log);
      default:
        console.error(`Unsupported modelType: ${modelType}`);
    }
  };

  const filteredLogs = logs.filter(filterCondition);

  return filteredLogs;
}

export function filterApprovedTotalLogs(logs: ApprovedTotalLogSignature[]) {
  if (!logs) {
    return [];
  }

  const filteredLogs = logs.filter((log, index) => {
    // Allow the first log even if to and from are the same
    if (index === 0) {
      return true;
    }

    // Filter out logs where to and from are the same
    return log.to !== log.from;
  });

  return filteredLogs;
}

export default null; // silence a false warning
