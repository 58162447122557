import { helper } from '@ember/component/helper';

export type ValidationError = {
  errors: string[];
  value: string;
  path: string;
};

/**
 * Returns an array of error messages for a given field if it exists in an array of ValidationErrors
 * for a yup object schema.
 *
 * @param {array} positional - The positional parameters for this function
 * @param {string} positional[0] - The field (ValidationError.path) to check for errors
 * @param {array} schemaErrors - An array of yup ValidationErrors (https://github.com/jquense/yup#validationerrorerrors-string--arraystring-value-any-path-string)
 *
 * @return {array} An array of error message strings
 */
function errorsForField(
  [field]: [string],
  { schemaErrors }: { schemaErrors: ValidationError[] }
): string[] {
  if ((!field && field !== '0') || !schemaErrors) {
    return [];
  }

  const error = schemaErrors.find((err) => {
    return err.path === field;
  });
  return error?.errors || [];
}

export default helper(errorsForField);
