import Model, { attr, hasMany } from '@ember-data/model';
import type CustomCompanyEmailTemplateModel from './custom-company-email-template.ts';

export default class EmailTemplateModel extends Model {
  @attr declare templateId: string;
  @attr declare templateKey: string;
  @attr declare description: string;
  @attr declare skipSend: boolean;
  @attr declare createdAt: string;

  @hasMany('custom-company-email-template', { async: false, inverse: 'emailTemplate' })
  declare customCompanyEmailTemplates: CustomCompanyEmailTemplateModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'email-template': EmailTemplateModel;
  }
}
