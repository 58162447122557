import type {
  TasApplicationState,
  TasApplicationPaymentState,
} from '../../types/tuition-assistance';
import type { Intent } from 'tio-ui/utilities';

const TasStateLabelMap = {
  // application states
  'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL': 'Pending',
  'TAS.ApplicationState.FULFILLED': 'Complete',
  CHANGES_REQUESTED: 'Change Requested',
  IN_PROGRESS: 'In Progress',
  CREATED: 'Created',
  'TAS.ApplicationState.PENDING_COURSES_APPROVAL': 'Pending',
  'TAS.ApplicationState.COURSES_APPROVED': 'Approved',
  'TAS.ApplicationState.COURSES_REJECTED': 'Application Rejected',
  'TAS.ApplicationState.EVIDENCE_APPROVED': 'Evidence Approved',
  'TAS.ApplicationState.EVIDENCE_NOT_APPROVED': 'Evidence Not Approved',
  EVIDENCE_NOT_APPROVED: 'Evidence Not Approved',
  'TAS.ApplicationState.EVIDENCE_REJECTED': 'Evidence Rejected',
  HIDDEN: 'Hidden',

  // payment states
  'TAS.ApplicationPaymentState.REFUND_REQUESTED': 'In Repayment',
};

const TasIntentMap: {
  [key: string]: Intent;
} = {
  // application states
  'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL': 'primary',
  'TAS.ApplicationState.FULFILLED': 'success',
  CHANGES_REQUESTED: 'info',
  IN_PROGRESS: 'info',
  CREATED: 'primary',
  'TAS.ApplicationState.PENDING_COURSES_APPROVAL': 'primary',
  'TAS.ApplicationState.COURSES_APPROVED': 'success',
  'TAS.ApplicationState.COURSES_REJECTED': 'error',
  'TAS.ApplicationState.EVIDENCE_APPROVED': 'success',
  'TAS.ApplicationState.EVIDENCE_NOT_APPROVED': 'error',
  EVIDENCE_NOT_APPROVED: 'error',
  'TAS.ApplicationState.EVIDENCE_REJECTED': 'error',
  HIDDEN: 'hidden',

  // payment states
  'TAS.ApplicationPaymentState.REFUND_REQUESTED': 'warning',
};

const formatStateKey = (
  state: TasApplicationState | TasApplicationPaymentState,
  fromState?: TasApplicationState
): string => {
  if (state === 'TAS.ApplicationState.ATTEND') {
    switch (fromState) {
      case 'TAS.ApplicationState.COURSES_NOT_APPROVED':
        return 'CHANGES_REQUESTED';
      case 'TAS.ApplicationState.COURSES_APPROVED':
        return 'IN_PROGRESS';
      case 'TAS.ApplicationState.EVIDENCE_NOT_APPROVED':
        return 'EVIDENCE_NOT_APPROVED';
      default:
        return 'HIDDEN';
    }
  }

  if (state === 'TAS.ApplicationState.DEFINE_COURSES') {
    if (fromState === 'TAS.ApplicationState.CREATED') return 'CREATED';
    return 'HIDDEN';
  }

  return state;
};

export const tasStateLabel = (
  state: TasApplicationState | TasApplicationPaymentState,
  fromState?: TasApplicationState
): string => {
  const formattedState = formatStateKey(state, fromState);
  return TasStateLabelMap[formattedState as keyof typeof TasStateLabelMap];
};

export const tasStateIntent = (
  state: TasApplicationState | TasApplicationPaymentState,
  fromState?: TasApplicationState
): Intent => {
  const formattedState = formatStateKey(state, fromState);
  return TasIntentMap[formattedState as keyof typeof TasIntentMap] || 'hidden';
};
