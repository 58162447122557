import Model, { attr, belongsTo } from '@ember-data/model';
import type AggregationAccountModel from './aggregation-account.ts';

export default class AggregationAccountObservationModel extends Model {
  @attr declare action: string;
  @attr declare actionReason: string;
  @attr declare agent: string;
  @attr declare at: string;
  @attr declare balance: number;
  @attr declare interestBalance: number;
  @attr declare principalBalance: number;
  @attr declare provider: string;
  @attr declare providerStatus: string;
  @attr declare via: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('aggregation-account', { async: false, inverse: null })
  declare aggregationAccount: AggregationAccountModel;

  /**************************
   **  Computed Properties **
   **************************/
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'aggregation-account-observation': AggregationAccountObservationModel;
  }
}
