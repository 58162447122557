import { object, number } from 'yup';
import type { AnyObject, ObjectShape, ObjectSchema } from 'yup';

interface schemaSignature {
  [key: string]: number | null | undefined;
}

const limitsGreaterThanZeroDynamicSchema = (
  fieldName: string,
  employmentTypes: string[]
): ObjectSchema<schemaSignature> => {
  const shape: ObjectShape = {};

  employmentTypes.forEach((type) => {
    shape[type] = number().positive('Limit must be greater than $0').nullable();
  });

  const schemaShape: ObjectShape = {
    [fieldName]: object().shape(shape),
  };
  return object().shape(schemaShape) as ObjectSchema<schemaSignature, AnyObject>;
};

const limitsForAllEmploymentTypes = object({
  MAXIMUM_LIFETIME_REIMBURSEMENT: number().positive('Limit must be greater than $0').nullable(),
  MAXIMUM_ANNUAL_REIMBURSEMENT: number().positive('Limit must be greater than $0').nullable(),
});

export { limitsGreaterThanZeroDynamicSchema, limitsForAllEmploymentTypes };

export default null; // silence a false warning
