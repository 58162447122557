import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from './user.ts';

export default class AuthenticationLogModel extends Model {
  @attr declare identity: string;
  @attr declare ipAddress: string;
  @attr declare loginAttemptStatus: string;
  @attr declare createdAt: string;

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'authentication-log': AuthenticationLogModel;
  }
}
