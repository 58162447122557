import { tracked } from '@glimmer/tracking';
import type { TasClaimsFinancials } from '../../types/tuition-assistance.ts';

export function createDefaultClaimsFinancialsWithBenefitPercent(
  benefitPercent: number = 1,
  requestedTotal: number = 0
): TasClaimsFinancials {
  return {
    CF_TUITION_CHARGED: requestedTotal,
    CF_DISCOUNT_AMOUNT: 0,
    CF_TUITION_AFTER_DISCOUNT: 0,
    CF_FEES: 0,
    CF_INVOICE_TOTAL: 0,
    CF_EMPLOYEE_POP_AMOUNT: 0,
    CF_LOAN_POP_AMOUNT: 0,
    CF_GRANT_POP_AMOUNT: 0,
    CF_OTHER_POP_AMOUNT: 0,
    CF_TOTAL_POP_AMOUNT: 0,
    CF_UNPAID_BALANCE: 0,
    CF_FRACTIONAL_PERCENT_PASSED: 0,
    CF_APPLICABLE_GRANT: 0,
    CF_GRADED_TUITION: 0,
    CF_PERCENT_TOTAL_COST: 0,
    CF_GRANT_DISTRIBUTION: 0,
    CF_BENEFIT_FRACTIONAL_PERCENT_APPLIED: benefitPercent,
    CF_BENEFIT_PERCENT_OF_GRADED_TUITION: 0,
    CF_GRADED_TUITION_LESS_GRANT_DISTRIBUTION: 0,
    CF_REIMBURSEMENT_TOTAL: 0,
  };
}

export type ClaimsFinancialsCalculatorTrackedPropName =
  | 'CF_TUITION_CHARGED'
  | 'CF_DISCOUNT_AMOUNT'
  | 'CF_FEES'
  | 'CF_EMPLOYEE_POP_AMOUNT'
  | 'CF_LOAN_POP_AMOUNT'
  | 'CF_GRANT_POP_AMOUNT'
  | 'CF_OTHER_POP_AMOUNT'
  | 'CF_FRACTIONAL_PERCENT_PASSED'
  | 'CF_BENEFIT_FRACTIONAL_PERCENT_APPLIED';

export default class ClaimsFinancialsCalculator {
  @tracked CF_TUITION_CHARGED: number;
  @tracked CF_DISCOUNT_AMOUNT: number;
  @tracked CF_FEES: number;

  @tracked CF_EMPLOYEE_POP_AMOUNT: number;
  @tracked CF_LOAN_POP_AMOUNT: number;
  @tracked CF_GRANT_POP_AMOUNT: number;
  @tracked CF_OTHER_POP_AMOUNT: number;

  // (# of credits passed / total credits)
  @tracked CF_FRACTIONAL_PERCENT_PASSED: number;
  // Benefit percent - if it is a UPMC employee, benefit percent is always at 100%. If it is a non-employee, benefit percent is always at 50%
  @tracked CF_BENEFIT_FRACTIONAL_PERCENT_APPLIED: number;

  get CF_TUITION_AFTER_DISCOUNT(): number {
    return this.CF_TUITION_CHARGED - this.CF_DISCOUNT_AMOUNT;
  }

  get CF_INVOICE_TOTAL(): number {
    return this.CF_TUITION_AFTER_DISCOUNT + this.CF_FEES;
  }

  get CF_TOTAL_POP_AMOUNT(): number {
    return (
      this.CF_EMPLOYEE_POP_AMOUNT +
      this.CF_LOAN_POP_AMOUNT +
      this.CF_GRANT_POP_AMOUNT +
      this.CF_OTHER_POP_AMOUNT
    );
  }

  get CF_UNPAID_BALANCE(): number {
    return this.CF_INVOICE_TOTAL - this.CF_TOTAL_POP_AMOUNT;
  }

  get CF_APPLICABLE_GRANT(): number {
    return this.CF_GRANT_POP_AMOUNT * this.CF_FRACTIONAL_PERCENT_PASSED;
  }

  get CF_GRADED_TUITION(): number {
    return this.CF_TUITION_AFTER_DISCOUNT * this.CF_FRACTIONAL_PERCENT_PASSED;
  }

  get CF_PERCENT_TOTAL_COST(): number {
    if (this.CF_GRADED_TUITION + this.CF_FEES === 0) {
      return 0;
    }
    return this.CF_GRADED_TUITION / (this.CF_GRADED_TUITION + this.CF_FEES);
  }

  get CF_GRANT_DISTRIBUTION(): number {
    return this.CF_PERCENT_TOTAL_COST * this.CF_APPLICABLE_GRANT;
  }

  get CF_BENEFIT_PERCENT_OF_GRADED_TUITION(): number {
    return this.CF_BENEFIT_FRACTIONAL_PERCENT_APPLIED * this.CF_GRADED_TUITION;
  }

  get CF_GRADED_TUITION_LESS_GRANT_DISTRIBUTION(): number {
    return this.CF_GRADED_TUITION - this.CF_GRANT_DISTRIBUTION;
  }

  get CF_REIMBURSEMENT_TOTAL(): number {
    return Math.round(
      Math.min(
        this.CF_BENEFIT_PERCENT_OF_GRADED_TUITION,
        this.CF_GRADED_TUITION_LESS_GRANT_DISTRIBUTION
      )
    );
  }

  constructor(claimsFinancials: TasClaimsFinancials) {
    this.CF_TUITION_CHARGED = claimsFinancials.CF_TUITION_CHARGED;
    this.CF_DISCOUNT_AMOUNT = claimsFinancials.CF_DISCOUNT_AMOUNT;
    this.CF_FEES = claimsFinancials.CF_FEES;
    this.CF_EMPLOYEE_POP_AMOUNT = claimsFinancials.CF_EMPLOYEE_POP_AMOUNT;
    this.CF_LOAN_POP_AMOUNT = claimsFinancials.CF_LOAN_POP_AMOUNT;
    this.CF_GRANT_POP_AMOUNT = claimsFinancials.CF_GRANT_POP_AMOUNT;
    this.CF_OTHER_POP_AMOUNT = claimsFinancials.CF_OTHER_POP_AMOUNT;
    this.CF_FRACTIONAL_PERCENT_PASSED = claimsFinancials.CF_FRACTIONAL_PERCENT_PASSED;
    this.CF_BENEFIT_FRACTIONAL_PERCENT_APPLIED =
      claimsFinancials.CF_BENEFIT_FRACTIONAL_PERCENT_APPLIED;
  }

  get results(): TasClaimsFinancials {
    return {
      CF_TUITION_CHARGED: this.CF_TUITION_CHARGED,
      CF_DISCOUNT_AMOUNT: this.CF_DISCOUNT_AMOUNT,
      CF_TUITION_AFTER_DISCOUNT: this.CF_TUITION_AFTER_DISCOUNT,
      CF_FEES: this.CF_FEES,
      CF_INVOICE_TOTAL: this.CF_INVOICE_TOTAL,
      CF_EMPLOYEE_POP_AMOUNT: this.CF_EMPLOYEE_POP_AMOUNT,
      CF_LOAN_POP_AMOUNT: this.CF_LOAN_POP_AMOUNT,
      CF_GRANT_POP_AMOUNT: this.CF_GRANT_POP_AMOUNT,
      CF_OTHER_POP_AMOUNT: this.CF_OTHER_POP_AMOUNT,
      CF_TOTAL_POP_AMOUNT: this.CF_TOTAL_POP_AMOUNT,
      CF_UNPAID_BALANCE: this.CF_UNPAID_BALANCE,
      CF_FRACTIONAL_PERCENT_PASSED: this.CF_FRACTIONAL_PERCENT_PASSED,
      CF_APPLICABLE_GRANT: this.CF_APPLICABLE_GRANT,
      CF_GRADED_TUITION: this.CF_GRADED_TUITION,
      CF_PERCENT_TOTAL_COST: this.CF_PERCENT_TOTAL_COST,
      CF_GRANT_DISTRIBUTION: this.CF_GRANT_DISTRIBUTION,
      CF_BENEFIT_FRACTIONAL_PERCENT_APPLIED: this.CF_BENEFIT_FRACTIONAL_PERCENT_APPLIED,
      CF_BENEFIT_PERCENT_OF_GRADED_TUITION: this.CF_BENEFIT_PERCENT_OF_GRADED_TUITION,
      CF_GRADED_TUITION_LESS_GRANT_DISTRIBUTION: this.CF_GRADED_TUITION_LESS_GRANT_DISTRIBUTION,
      CF_REIMBURSEMENT_TOTAL: this.CF_REIMBURSEMENT_TOTAL,
    };
  }
}
