import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type UserModel from './user.ts';
import type EmployeeModel from './employee.ts';
import type FamilyInviteModel from './family-invite.ts';

export default class FamilyModel extends Model {
  @attr declare firstName: string;
  @attr declare lastName: string;
  @attr declare familyStatusType: string;
  @attr declare familyRelationshipTypeId: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;
  @belongsTo('employee', { async: false, inverse: null })
  declare employee: EmployeeModel;
  @hasMany('family-invite', { async: false, inverse: null })
  declare familyInvites: FamilyInviteModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get fullName() {
    return [this.firstName, this.lastName].join(' ');
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    family: FamilyModel;
  }
}
