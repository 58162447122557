import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from './user.ts';

type EventContext = {
  eventName: string;
  message: string;
};

export default class RemoteEventModel extends Model {
  @attr declare eventType: string;
  @attr declare payload: {
    data: {
      event: {
        spinwheel?: { context: EventContext };
        finicity?: { context: EventContext };
      };
    };
  };
  @attr declare at: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'remote-event': RemoteEventModel;
  }
}
