import Model, { attr } from '@ember-data/model';

export default class LimitModel extends Model {
  @attr declare product: string;
  @attr declare scheme: string;
  @attr declare group: string;
  @attr declare amount: number | null;
  @attr declare annualAmount: number | null;
  @attr declare sixMonthAmount: number | null;
  @attr declare threeMonthAmount: number | null;
  @attr declare taxableAmount: number | null;
  @attr declare hard: boolean;
  @attr declare description: string;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    limit: LimitModel;
  }
}
