import { object, string, number, date } from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const employeeSchema = object({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  email: string().email().required('E-mail is required'),
  payrollId: string().required('Payroll ID is required'),
  contributionAmount: number().integer().optional(),
  employmentStartDate: date().optional(),
  employmentEndDate: date().optional(),
  eligibilityStartDate: date().optional(),
  eligibilityEndDate: date().optional(),
  currency: string().optional(),
  salary: number().integer().optional(),
  title: string().optional(),
  department: string().optional(),
  costCenter: string().optional(),
  ratePeriod: string().optional(),
  employmentType: string().optional(),
  phoneNumber: string().matches(phoneRegExp, 'Phone number is not valid'),
  addressLine1: string().optional(),
  addressLine2: string().optional(),
  city: string().optional(),
  state: string().optional(),
  postalCode: string().optional(),
  isActive: string().optional(),
  birthYear: number().integer().optional(),
  customData: string().optional(),
});

export default employeeSchema;
