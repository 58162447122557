/**
 * Creates an object lookup from an array.
 *
 * @param {object[]} array         An array of objects
 * @param {string}   keyProperty   The property to use as the key for the lookup. Defaults to `id`
 * @param {string}   valueProperty The property to use as the value. If `undefined`, the entire
 *                                 object is used
 * @param {boolean}  duplicateKeys Whether duplicate keys are allowed. If `true`, then the value
 *                                 of each key will be an array of values
 *
 * @return {object} An object mapping the `keyProperty` to the `valueProperty` for all elements in
 *                  the array. If `duplicateKeys` is set, then the value property will be an an
 *                  array of values.
 */

interface SingleKeyLookup<TValue> {
  [key: string]: TValue;
}

interface MultiKeyLookup<TValue> {
  [key: string]: TValue[];
}

// Overload for duplicateKeys = false (or not provided)
type CreateLookupFn = {
  <TItem, TKey extends keyof TItem>(
    array: TItem[],
    keyProperty?: TKey,
    valueProperty?: TKey,
    duplicateKeys?: false
  ): SingleKeyLookup<TItem[TKey]>;
  <TItem, TKey extends keyof TItem>(
    array: TItem[],
    keyProperty: TKey,
    valueProperty: TKey,
    duplicateKeys: true
  ): MultiKeyLookup<TItem[TKey]>;
};

export default function CreateLookupFn<TItem, TKey extends keyof TItem>(
  array: TItem[],
  keyProperty: TKey = 'id' as TKey,
  valueProperty?: TKey,
  duplicateKeys: boolean = false
): SingleKeyLookup<TItem[TKey]> | MultiKeyLookup<TItem[TKey]> {
  return !array
    ? {}
    : array.reduce(
        (lookup, item: TItem) => {
          const key = String(item[keyProperty]);
          const value = valueProperty ? item[valueProperty] : item;

          if (duplicateKeys) {
            if (!lookup[key]) lookup[key] = [];
            // @ts-expect-error: need better understanding of this method.
            (lookup[key] as TItem[TKey][]).push(value);
          } else {
            lookup[key] = value as TItem[TKey];
          }

          return lookup;
        },
        {} as SingleKeyLookup<TItem[TKey]> | MultiKeyLookup<TItem[TKey]>
      );
}
