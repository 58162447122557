import { helper } from '@ember/component/helper';
import type TasProgramTemplate from '../models/tas-program-template.ts';
import type { TemplateFieldName } from '../utils/tuition-assistance/fields.ts';

/**
  {{is-program-visible "SCHOOL_INSTITUTION_NAME" programTemplate=@tasProgramTemplate}}
 */
export default helper(function isProgramVisible(
  [field]: [TemplateFieldName],
  { programTemplate }: { programTemplate: TasProgramTemplate }
) {
  if (!field || !programTemplate) {
    return false;
  }

  return !!programTemplate.fields?.[field]?.visible;
});
