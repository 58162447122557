interface SubdomainRedirectArgs {
  subDomain: string;
  domain: string;
  route: string;
  email?: string;
}

export default function formatSubdomainRedirectUrl({
  subDomain,
  domain,
  route,
  email,
}: SubdomainRedirectArgs) {
  const { port, protocol, search } = window.location;
  const formattedPort = port ? `:${port}` : '';
  const formattedProtocol = protocol === 'http:' ? 'http' : 'https';
  const formattedDomain = domain === 'localhost' ? 'tuition' : domain;
  let redirectUrl = `${formattedProtocol}://${subDomain}.${formattedDomain}.io${formattedPort}/${route}`;
  const queryParams = new URLSearchParams(search);

  if (email) {
    queryParams.set('email', encodeURIComponent(email));
  }

  if (queryParams.toString()) {
    redirectUrl += '?' + queryParams.toString();
  }

  return redirectUrl;
}
