import Model, { attr, belongsTo } from '@ember-data/model';

import type MatchParticipantModel from './match-participant.ts';
import type AccountTransactionModel from './account-transaction.ts';
import type AccountModel from './account.ts';

type MatchPaymentTransitionLogItem = Record<string, unknown>;

export const MATCH_PAYMENT_QUALIFICATION_STATE = {
  QUALIFIED: 'SYF.PaymentQualificationState.QUALIFIED',
  UNQUALIFIED: 'SYF.PaymentQualificationState.UNQUALIFIED',
  CREATED: 'SYF.PaymentQualificationState.CREATED',
} as const;

type QualificationStateMap = typeof MATCH_PAYMENT_QUALIFICATION_STATE;
type QualificationStateKey = keyof typeof MATCH_PAYMENT_QUALIFICATION_STATE;

export type MatchPaymentQualificationState = QualificationStateMap[QualificationStateKey];

export default class MatchPayment extends Model {
  @attr declare transactionAmount: number;
  @attr declare transactionDate: string;
  @attr declare qualificationState: string;
  @attr declare transitionLog: Array<MatchPaymentTransitionLogItem>;
  @attr declare certified: boolean;

  @belongsTo('match-participant', { async: false, inverse: null })
  declare matchParticipant: MatchParticipantModel;

  @belongsTo('account-transaction', { async: false, inverse: 'matchPayments' })
  declare accountTransaction: AccountTransactionModel;

  @belongsTo('account', { async: false, inverse: 'match-payment' })
  declare account: AccountModel;
}
