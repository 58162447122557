import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from './user.ts';
import type ConditionModel from './condition.ts';

export default class AgreementModel extends Model {
  @attr declare classification: string;
  @attr declare province: string;
  @attr declare agreedUponFilename: string;
  @attr declare agreedUponText: string;
  @attr declare expiryDate: string;
  @attr declare isExpired: boolean;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: 'agreements' })
  declare user: UserModel;
  @belongsTo('condition', { async: false, inverse: 'agreements' })
  declare condition: ConditionModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    agreement: AgreementModel;
  }
}
