import Model, { attr } from '@ember-data/model';

export default class DapipInstitutionCampusViewModel extends Model {
  @attr declare dapipId: number;
  @attr declare parentDapipId: number;
  @attr declare address: string;
  @attr declare locationName: string;
  @attr declare locationType: string;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'dapip-institution-campus-view': DapipInstitutionCampusViewModel;
  }
}
