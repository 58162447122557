import Model, { attr, belongsTo } from '@ember-data/model';
import type TasProgramTemplateModel from './tas-program-template.ts';
import type PublicAssetModel from './public-asset.ts';
import type DapipInstitutionCampusViewModel from './dapip-institution-campus-view.ts';
import type TasUnaccreditedInstitutionModel from './tas-unaccredited-institution.ts';

export default class TasProgramTemplateInstitutionModel extends Model {
  @attr declare isFeatured: boolean;
  @attr declare isPreferred: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('tas-program-template', { async: false, inverse: null })
  declare tasProgramTemplate: TasProgramTemplateModel;
  @belongsTo('public-asset', { async: false, inverse: null })
  declare logo: PublicAssetModel;
  @belongsTo('dapip-institution-campus-view', { async: false, inverse: null })
  declare accreditedInstitution: DapipInstitutionCampusViewModel;
  @belongsTo('tas-unaccredited-institution', { async: false, inverse: null })
  declare unaccreditedInstitution: TasUnaccreditedInstitutionModel;

  /*************************
   **  Computed Properties **
   *************************/
  get locationName() {
    return (
      this.accreditedInstitution?.locationName || this.unaccreditedInstitution?.locationName || ''
    );
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-program-template-institution': TasProgramTemplateInstitutionModel;
  }
}
