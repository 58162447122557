import Model, { attr, belongsTo } from '@ember-data/model';
import type AggregationAccountModel from './aggregation-account.ts';

export default class AggregationInstitutionModel extends Model {
  @attr declare primaryProvider: string;
  @attr declare name: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('aggregation-account', { async: false, inverse: null })
  declare aggregationAccount: AggregationAccountModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'aggregation-institution': AggregationInstitutionModel;
  }
}
