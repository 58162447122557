/**
 * Resolves template literal variables in the form `${variable}` in a string
 * dynamically by looking up values in `context`. Only resolves variables; does
 * not handle any expressions within the template literal substitution syntax.
 *
 * NOTE: Solution based off the answer to [this Stack Overflow
 *       question](https://stackoverflow.com/questions/30003353/can-es6-template-literals-be-substituted-at-runtime-or-reused/55594573#55594573)
 *       [twl 6.Aug.22]
 *
 * @param  {string} text    A string containing template literal expressions
 * @param  {object} context An object mapping variable names to values
 *
 * @return {string} A string with template literal variables resolved to their values
 */

const resolveTemplateLiteralVariables = (text: string, context: HTMLElement) =>
  // @ts-expect-error: we don't fully know context. Should error check.
  text.replace(/\${(.*?)}/g, (_expression, variable) => context[variable]);

export { resolveTemplateLiteralVariables };

export default null; // silence a false warning
