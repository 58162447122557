import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type Store from '@ember-data/store';
import { service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import type Intl from 'ember-intl/services/intl';
import type PersonModel from './person.ts';
import type AgentModel from './agent.ts';
import type AnswerProfileModel from './answer-profile.ts';
import type AccountModel from './account.ts';
import type RemoteEventModel from './remote-event.ts';
import type RecommendationModel from './recommendation.ts';
import type EmployeeModel from './employee.ts';
import type RoleViewModel from './role-view.ts';
import type LoginModel from './login.ts';
import type StrategyFinderResultModel from './strategy-finder-result.ts';
import type AgreementModel from './agreement.ts';
import type EmailAddressModel from './email-address.ts';

export default class UserModel extends Model {
  @service declare intl: Intl;
  @service declare store: typeof Store;

  @attr declare updatedAt: string;
  @attr declare createdAt: string;
  @attr declare isActive: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('person', { async: false, inverse: 'user' })
  declare person: PersonModel;
  @belongsTo('agent', { async: false, inverse: null })
  declare agent: AgentModel;
  @belongsTo('answer-profile', { async: false, inverse: null })
  declare answerProfile: AnswerProfileModel;
  @hasMany('account', { async: false, inverse: null })
  declare accounts: AccountModel[];
  @hasMany('remote-event', { async: false, inverse: null })
  declare remoteEvents: RemoteEventModel[];
  @hasMany('recommendation', { async: false, inverse: null })
  declare recommendations: RecommendationModel[];
  @hasMany('employee', { async: false, inverse: null })
  declare employees: EmployeeModel[];
  @hasMany('user', { async: false, inverse: null })
  declare families: UserModel[];
  @hasMany('role-view', { async: false, inverse: null })
  declare roleViews: RoleViewModel[];
  @hasMany('login', { async: false, inverse: null })
  declare logins: LoginModel[];
  @hasMany('strategy-finder-result', { async: false, inverse: null })
  declare strategyFinderResults: StrategyFinderResultModel[];
  @hasMany('agreement', { async: false, inverse: 'user' })
  declare agreements: AgreementModel[];
  @belongsTo('agreement', { async: false, inverse: null })
  declare usageAgreement: AgreementModel;
  @belongsTo('agreement', { async: false, inverse: null })
  declare privacyAgreement: AgreementModel;
  @hasMany('agreement', { async: false, inverse: null })
  declare customRegisterAgreements: AgreementModel;
  @hasMany('email-address', { async: false, inverse: 'user' })
  declare emailAddresses: EmailAddressModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get roles() {
    return this.roleViews || [];
  }

  get primaryEmail() {
    return this.emailAddresses?.find((e) => e.contactType === 'PRIMARY');
  }

  get personalEmail() {
    return this.emailAddresses?.find((e) => e.contactType === 'PERSONAL');
  }

  @cached
  get uniqueRolesByRelationshipType() {
    const relationshipMap = new Map();
    this.roles.forEach((role) => {
      const existingRole = relationshipMap.get(role.relationshipType);
      if (!existingRole) {
        // If no role exists for this relationshipType, add it
        relationshipMap.set(role.relationshipType, role);
      } else if (!existingRole.isActive && role.isActive) {
        // If a duplicate exists, prefer the active role
        relationshipMap.set(role.relationshipType, role);
      }
      // If both are active or both are inactive, keep the first one (no action needed)
    });

    const uniqueRoles = Array.from(relationshipMap.values());
    return Object.freeze(uniqueRoles);
  }

  get activeAccounts() {
    return this.accounts?.filter((e) => !e.deactivatedOn) || [];
  }

  get activeLoans() {
    return this.activeAccounts.map((account) => account.activeLoans).flat();
  }

  get totalLoanBalance() {
    return this.activeAccounts.reduce((sum, account) => {
      return (sum += account.loanTotal);
    }, 0);
  }

  get needsToAnswerWhyNoLinkedLoans() {
    return !!this.person?.needsToAnswerWhyNoLinkedLoans;
  }

  get isTioAdmin() {
    return !!this.roleViews?.find((role) => {
      return role.relationshipType === 'TIO_ADMIN';
    });
  }

  get isEmployee() {
    return !!this.roleViews?.find((role) => {
      return role.relationshipType === 'PARTICIPANT';
    });
  }

  get isEmployeeAdmin() {
    return !!this.roleViews?.find((role) => {
      return role.relationshipType === 'EMPLOYEE_ADMIN';
    });
  }

  get isAccountOwner() {
    return !!this.roleViews?.find((role) => {
      return role.relationshipType === 'ACCOUNT_OWNER';
    });
  }

  get isAccountOwnerOrEmployeeAdmin() {
    return this.isAccountOwner || this.isEmployeeAdmin;
  }

  get isAgent() {
    return !!this.roleViews?.find((role) => {
      return role.role === 'WORKSPACE_AGENT';
    });
  }

  get isFamily() {
    return !!this.roleViews?.find((role) => {
      return role.role === 'FAMILY_MEMBER';
    });
  }

  get isPslfAdmin() {
    return !!this.roleViews?.find((role) => {
      return role.relationshipType === 'PSLF_ADMIN';
    });
  }

  get isTasTioApprover() {
    return !!this.roleViews?.find((role) => {
      return role.relationshipType === 'TAS.Approver.TIO_APPROVER';
    });
  }

  get isTasApprover() {
    return !!this.roleViews?.find((role) => {
      return role.relationshipType.startsWith('TAS.Approver');
    });
  }

  get canEditTasApplication() {
    const approvedRoles = [
      'TAS.Admin.PROGRAM_ADMIN',
      'TAS.Admin.SUPERUSER',
      'TAS.Approver.ADMIN_APPROVER',
      'TAS.Approver.APPROVER',
      'TAS.Approver.CUSTOM_APPROVER',
      'TAS.Approver.FINANCE_APPROVER',
      'TAS.Approver.HYPERVISOR',
      'TAS.Approver.PPP_FINANCE_APPROVER',
      'TAS.Approver.TAP_FINANCE_APPROVER',
      'TAS.Approver.TIO_APPROVER',
      'TIO_ACCOUNT_MANAGER',
      'TIO_ADMIN',
      'EMPLOYEE_ADMIN',
    ];

    return !!this.roleViews?.find((role) => {
      return approvedRoles.includes(role.relationshipType);
    });
  }

  /**
   * Queries the API for aggregated data that this user has access to.
   *
   * The query can contain one or more of the following properties:
   *
   * `model`
   * : The model whose data is being aggregated
   *
   * `groupBy`
   * : Which property of the model should be used to group the data before aggregation
   *
   * `rollups`
   * : An object mapping field names to the formulas used to calculate the rollup values for those
   *   fields.
   *
   * The return value will be an object in the form:
   *
   * ```
   * {
   *   data: {
   *     name?: string;       // the value of the `groupBy`` property for this group
   *     ...rollups?: any;    // the computed rollup values for this group
   *   }
   * }
   * ````
   */
  async aggregate(query: Record<string, unknown>) {
    return await this.store.adapterFor('user').aggregate(this, query);
  }

  /**
   * Queries the API for time series data that this user has access to.
   *
   * The query can contain one or more of the following properties:
   *
   * `model`
   * : The model whose time series data is being requested
   *
   * `value`
   * : The property to return as the value for the time series data
   *
   * The return value will be an object in the form:
   *
   * ```
   * {
   *   data: ITimeSeries[],
   * }
   * ````
   *
   * Where `ITimeSeries` is:
   *
   * ```
   * {
   *   id: any;                // the unique identifier for this series, usually the entity ID
   *   label: string;          // a label to identify this series in the UI
   *   entity?: Model;         // an instance of the model whose data is being quried
   *   data: ITimeSeriesData[] // the individual data points for this series
   * }
   * ```
   *
   * Where `ITimeSeriesData` is:
   *
   * ```
   * {
   *   at: DateTimeValue        // A value that can be converted to a datetime
   *   value: number            // The value for this data point
   * }
   * ```
   */
  async timeSeries(query: Record<string, unknown>) {
    return await this.store.adapterFor('user').timeSeries(this, query);
  }

  async deactivateUser(userId: string) {
    return await this.store.adapterFor('user').deactivateUser(userId);
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
