import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import type MatchPaymentModel from './match-payment.ts';
import type FinancialInstitutionModel from './financial-institution.ts';
import type MatchAssetModel from './match-asset.ts';
import type PersonModel from './person.ts';

export const ACCOUNT_TRANSACTION_TYPE = {
  PAYMENT: 'TransactionType.PAYMENT',
  INTEREST: 'TransactionType.INTEREST',
  AMBIGUOUS: 'TransactionType.AMBIGUOUS',
} as const;

type AccountTransactionTypeMap = typeof ACCOUNT_TRANSACTION_TYPE;
type AccountTransactionTypeKey = keyof typeof ACCOUNT_TRANSACTION_TYPE;

export type AccountTransactionType = AccountTransactionTypeMap[AccountTransactionTypeKey];

export const ACCOUNT_TRANSACTION_CATEGORY = {
  CREDIT: 'TransactionCategory.CREDIT',
  DEBIT: 'TransactionCategory.DEBIT',
  AMBIGUOUS: 'TransactionCategory.AMBIGUOUS',
} as const;

type AccountTransactionCategoryMap = typeof ACCOUNT_TRANSACTION_TYPE;
type AccountTransactionCategoryKey = keyof typeof ACCOUNT_TRANSACTION_TYPE;

export type AccountTransactionCategory =
  AccountTransactionCategoryMap[AccountTransactionCategoryKey];

export const ACCOUNT_TRANSACTION_VERIFICATION_STATE = {
  PENDING_VERIFICATION: 'TransactionVerificationState.PENDING_VERIFICATION',
  VERIFIED: 'TransactionVerificationState.VERIFIED',
  REJECTED: 'TransactionVerificationState.REJECTED',
};

type AccountTransactionVerificationStateMap = typeof ACCOUNT_TRANSACTION_TYPE;
type AccountTransactionVerificationStateKey = keyof typeof ACCOUNT_TRANSACTION_TYPE;

export type AccountTransactionVerificationState =
  AccountTransactionVerificationStateMap[AccountTransactionVerificationStateKey];

type TransitionLogItem = {
  to: string;
  reason: {
    note: string;
  };
};

export default class AccountTransactionModel extends Model {
  @attr declare amount: number;
  @attr declare transactionDate: string;
  @attr declare transactionType: AccountTransactionType;
  @attr declare category: AccountTransactionCategory;
  @attr declare verificationState: AccountTransactionVerificationState;
  @attr declare createdAt: string;
  @attr declare transitionLog: TransitionLogItem[];

  @hasMany('match-payment', { async: false, inverse: null })
  declare matchPayments: MatchPaymentModel[];
  @hasMany('match-asset', { async: false, inverse: 'accountTransactions' })
  declare matchAssets: MatchAssetModel[];

  @belongsTo('financial-institution', { async: false, inverse: null })
  declare financialInstitution: FinancialInstitutionModel;
  @belongsTo('person', { async: false, inverse: 'accountTransactions' })
  declare person: PersonModel;

  get rejectionReason(): string {
    const { transitionLog = [] } = this;
    const rejection = transitionLog.find(
      (logItem: TransitionLogItem) => logItem.to === 'TransactionVerificationState.REJECTED'
    );
    return rejection?.reason?.note || '';
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-transaction': AccountTransactionModel;
  }
}
