import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountingInvoiceModel from './accounting-invoice.ts';
import type EmployeeModel from './employee.ts';
import type PlanModel from './plan.ts';

export default class AccountingLineItemModel extends Model {
  @attr declare amount: number;
  @attr declare lineItemType: string;
  @attr declare description: string;
  @attr declare date: string;
  @attr declare period: string;
  @attr declare state: string;
  @attr declare tier: string;
  @attr declare validationErrors: Record<string, unknown>;
  @attr declare validationState: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('accounting-invoice', { async: false, inverse: null })
  declare accountingInvoice: AccountingInvoiceModel;

  @belongsTo('employee', { async: false, inverse: null })
  declare employee: EmployeeModel;

  @belongsTo('plan', { async: false, inverse: null })
  declare plan: PlanModel;

  /**************************
   **  Computed Properties **
   **************************/

  get isCredit() {
    return this.lineItemType === 'REFUND';
  }

  get primaryValidationError() {
    return this.validationErrors?.[0];
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'accounting-line-item': AccountingLineItemModel;
  }
}
