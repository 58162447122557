import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

type Positional = [number | null | undefined];
type Named = {
  // TODO: Seems like this can come from addon
  style?: 'currency' | 'unit' | 'decimal' | 'percent' | undefined;
  currency?: string;
  maximumFractionDigits?: number;
};

export interface SafeFormatNumberSignature {
  Args: {
    Positional: Positional;
    Named: Named;
  };
  Return: string | null;
}

/**
 * Returns a formatted number if a parsable value exists, otherwise return null
 * without raising an app-blocking error.
 *
 * {{safe-format-number @currentBalance style="currency" currency="USD"}}
 *
 */
export default class SafeFormatNumber extends Helper<SafeFormatNumberSignature> {
  @service declare intl: IntlService;

  compute([valueToFormat]: Positional, named: Named = {}) {
    if (valueToFormat === null || valueToFormat === undefined) {
      return null;
    }

    if (isNaN(valueToFormat)) {
      console.warn('Unable to format NaN, returning null');
      return null;
    }

    try {
      return this.intl.formatNumber(valueToFormat, named);
    } catch (e) {
      console.warn(e);
    }
    return null;
  }
}
