import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type { FileQueueService } from 'ember-file-upload';

export default class EligibilityUploadService extends Service {
  @service declare fileQueue: FileQueueService;

  // TODO: This has something to do with the table. Not sully sure.
  @tracked aborted: string[] = [];
  // TODO: The uploader component modifies this value directly. Seems like it should be an
  // argument to uploadEligibilityFile.
  @tracked currentUploadUrl: string = '';

  // TODO: This has something to do with the table. Not sully sure.
  get listOfAbortedEligibilityProcess() {
    return this.aborted;
  }

  async uploadEligibilityFile() {
    const queue = this.fileQueue.find('eligibility-upload-queue');

    // Return a new Promise
    return new Promise<boolean>((resolve, reject) => {
      // TODO: There is a weird timing bug with the file upload modifying a tracked prop twice in the same loop.
      setTimeout(async () => {
        try {
          const response = await queue?.files[0]?.uploadBinary(this.currentUploadUrl, {
            method: 'PUT',
          });

          resolve(response?.ok || false);
        } catch (_error) {
          reject(false);
        }
      }, 1);
    });
  }
}
