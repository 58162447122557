import Model, { attr } from '@ember-data/model';

export interface EnumMetadata {
  'is-tio-employee': boolean;
  'governable-type': 'Employee' | 'Company';
  'governor-type': 'Role' | 'RelationshipType';
  'user-relationship-type': string;
}

export default class EnumModel extends Model {
  @attr declare flavor: string;
  @attr declare name: string;
  @attr declare dot: string;
  @attr declare description: string;
  @attr declare metadata: EnumMetadata;

  /*************************
   **  Relationships      **
   *************************/

  /**************************
   **  Computed Properties **
   **************************/
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    enum: EnumModel;
  }
}
