import Model, { attr, belongsTo } from '@ember-data/model';
import type CompanyModel from './company.ts';
import type UserModel from './user.ts';

export default class RoleView extends Model {
  @attr declare role: string;
  @attr declare relationshipType: string;
  @attr declare isActive: boolean;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('company', { async: false, inverse: null })
  declare company: CompanyModel;
  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;

  /**************************
   **  Computed Properties **
   ***************************/

  get roleType() {
    return this.role;
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'role-view': RoleView;
  }
}
