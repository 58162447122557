import { hasMany } from '@ember-data/model';
import DigitalAsset from './digital-asset.ts';
import type AccountTransactionModel from './account-transaction.ts';

export default class MatchAssetModel extends DigitalAsset {
  /*************************
   **  Relationships      **
   *************************/

  @hasMany('account-transaction', { async: false, inverse: 'matchAssets' })
  declare accountTransactions: AccountTransactionModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'match-asset': MatchAssetModel;
  }
}
