import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SideDrawerService extends Service {
  @tracked isOpen = false;

  @action
  closeDrawer() {
    this.isOpen = true;
    this.toggleDrawer();
  }

  @action
  toggleDrawer() {
    this.isOpen = !this.isOpen;
  }
}
