import Model, { attr, belongsTo } from '@ember-data/model';
import type CompanyModel from './company.ts';

export default class TasPaymentModel extends Model {
  @attr declare paymentDate: string;
  @attr declare description: string;
  @attr declare state: string;
  @attr declare paymentType: string;
  @attr declare paymentFilePaths: string[];
  @attr declare createdBy: string;
  @attr declare stats: Record<string, unknown>;

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('company', { async: false, inverse: null })
  declare company: CompanyModel;

  /**************************
   **  Computed Properties **
   **************************/

  get isCreated() {
    return this.state === 'CREATED';
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-payment': TasPaymentModel;
  }
}
