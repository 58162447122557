import Model, { attr, belongsTo } from '@ember-data/model';
import type AggregationAccountModel from './aggregation-account.ts';

export default class AggregationLoanModel extends Model {
  @attr declare name: string;
  @attr declare lastObservation: Record<string, unknown>;
  @attr declare accountNumber: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('aggregation-account', { async: false })
  declare aggregationAccount: AggregationAccountModel;

  /**************************
   **  Computed Properties **
   **************************/

  get rawLoanType() {
    return this.lastObservation['raw-loan-type'] as string;
  }

  get providerLoanStatus() {
    return this.lastObservation['provider-loan-status'] as string;
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'aggregation-loan': AggregationLoanModel;
  }
}
