import { object, string, number, date } from 'yup';

const createUnlinkedAccountForm = object({
  selectedServicer: string().required('Please select a servicer.'),
  servicerAddress: string().required('Please select an address.'),
  name: string().required('Please enter an account name.'),
  paymountAccountNumber: string().required('Please input a payment account number.'),
});

const createUnlinkedLoanForm = object({
  balance: number().required('Please enter a balance.'),
  name: string().required('Please enter a loan name.'),
  rawLoanType: string().required('Please enter a raw loan type.'),
  loanClass: string().required('Please select a loan class.'),
  fundSource: string().required('Please select a fund source.'),
  balanceDate: date()
    .required('Please select a date.')
    .test('date-range', 'Balance date must be within the last 60 days.', function (value) {
      const sixtyDaysAgo = new Date();
      sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
      return value > sixtyDaysAgo;
    }),
  interestRate: number().required('Please enter an interest rate.'),
  repaymentPlan: string().required('Please select a repayment plan.'),
  status: string().required('Please select a status.'),
});

const agentLoanSchema = object({
  loanType: string().required('Please select a loan type.'),
  totalDisbursedAmount: number().required('Please enter an amount.'),
  currentBalance: number().required('Please enter a balance.'),
  interestRate: number().required('Please select an interest rate.'),
  monthlyPayment: string().required('Please enter a payment amount.'),
  repaymentPlan: string().required('Please select a repayment plan.'),
});

export { createUnlinkedAccountForm, createUnlinkedLoanForm, agentLoanSchema };

export default null; // silence a false warning
