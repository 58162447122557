import Model, { attr, belongsTo } from '@ember-data/model';
import type DigitalAssetModel from './digital-asset.ts';

export default class AttachmentModel extends Model {
  @attr declare signedUrl: string;
  @attr declare classification: string;
  @attr declare createdAt: string;
  @attr declare description: string;
  @attr declare tags: string[];

  @attr declare assetClassification: string;
  @attr declare assetDescription: string;

  get displayClassification(): string {
    return this.classification || this.assetClassification;
  }

  get displayDescription(): string {
    return this.description || this.assetDescription;
  }

  @belongsTo('digitalAsset', { async: false, inverse: null })
  declare digitalAsset: DigitalAssetModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    attachment: AttachmentModel;
  }
}
