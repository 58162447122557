import Model, { attr } from '@ember-data/model';

type Highlight = {
  [key: string]: string[];
};

type Employee = {
  companyId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  payrollId: string;
  email: string;
  tasParticipantState: string;
};

export type Person = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;

  employees: Employee[];
  isActive: boolean;
  userNames: string[];

  jurisdictions: string[];
  highlight: Highlight;
};

export type PslfForm = {
  borrowerEmail: string;
  borrowerName: string;
  borrowerSignDate: string;
  borrowerEmployerEmail: string;
  companyId: string;
  documentId: string;
  employerClosed: string;
  employerEmail: string;
  employerName: string;
  employerSignDate: string;
  formAwaitingSignature: boolean;
  formFaxVerified: boolean;
  formId: string;
  formPreApproved: boolean;
  formPrintAndMail: boolean;
  formStatus: string;
  formNonPartner: boolean;
  formType: string;
  payrollId: string;
  profileId: string;
  createdAt: string;
  updatedAt: string;
};

export type ResultItems = Person | PslfForm;

export default class SearchModel<T> extends Model {
  @attr declare items: T[];
  @attr declare total: number;
  @attr declare took: number;
}
