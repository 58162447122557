import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from './user.ts';

export default class PostalAddressModel extends Model {
  @attr declare addressLine1: string;
  @attr declare addressLine2: string;
  @attr declare addressLine3: string;
  @attr declare city: string;
  @attr declare state: string;
  @attr declare postalCode: string;
  @attr declare country: string;
  @attr declare isActive: boolean;
  @attr declare isVerified: boolean;
  @attr declare postalAddressType: string;
  @attr declare paymentMethodType: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'postal-address': PostalAddressModel;
  }
}
