import Model, { attr, belongsTo } from '@ember-data/model';
import type TaggableModel from './taggable.ts';

export default class TagModel extends Model {
  @attr declare name: string;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('taggable', {
    async: false,
    polymorphic: true,
    inverse: 'tags',
  })
  declare taggable: TaggableModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tag: TagModel;
  }
}
