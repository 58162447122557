import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type UserModel from './user.ts';

export interface BestPlanNextStepsSignature {
  title: string;
  subtitle: string;
  instructions: string[];
}

export type RecommendationInput = {
  // TODO: Can we somehow unify the use of
  // number and string for these values?
  'academic-degree': string;
  'afford-payments': string;
  'current-forgiveness-program': string;
  'current-working': string;
  'employment-type': string;
  'federal-loan-time': string;
  'household-income': number | string;
  'household-size': number;
  'made-limited-waiver-payments': number;
  'made-payments': number;
  'payoff-goal': string;
  'plan-as-teacher': string;
  'plan-in-government': string;
  'residence-state': string;
};

export default class RecommendationModel extends Model {
  @service declare store: typeof Store;

  @attr declare calculationInput: Record<string, unknown>;
  @attr declare calculationOutput: Record<string, unknown>;
  @attr declare createdAt: string;
  @attr declare requestingApp: string;

  @attr declare inputSummary: RecommendationInput;
  @attr declare outputSummary: Record<string, unknown>;

  @attr declare currentPlanSummary: Record<string, unknown>;
  @attr declare currentPlanTableHeadings: Record<string, unknown>[];

  @attr declare bestPlanSummary: Record<string, unknown>;
  @attr declare bestPlanTableHeadings: Record<string, unknown>[];
  @attr declare bestPlanNextSteps: BestPlanNextStepsSignature[];

  @attr declare directLoansInput: Record<string, unknown>;
  @attr declare ffelLoansInput: Record<string, unknown>;
  @attr declare plusLoansInput: Record<string, unknown>;

  @attr declare directLoansOutput: Record<string, unknown>;
  @attr declare ffelLoansOutput: Record<string, unknown>;
  @attr declare plusLoansOutput: Record<string, unknown>;

  @attr declare hasErrorInCalcs: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;

  /*************************
   ** Computed Properties **
   *************************/

  get formattedInputSummary() {
    const input = this.inputSummary || {};
    const affordPayment = input['afford-payments'];
    const payoffGoal = input['payoff-goal'];
    const before2007 = input['federal-loan-time'];
    const currentlyWorking = input['current-working'];
    const monthsOfPayments = input['made-payments'];
    const currentForgivenessProgram = input['current-forgiveness-program'];
    const degree = input['academic-degree'];
    const employmentType = input['employment-type'];
    const planInGov = input['plan-in-government'];
    const planAsTeacher = input['plan-as-teacher'];
    const oneTimeAdjustmentPayments = input['made-limited-waiver-payments'];
    const income = input['household-income']; // in cents
    const familySize = input['household-size'];
    const state = input['residence-state'];

    return {
      affordPayment,
      payoffGoal,
      before2007,
      currentlyWorking,
      monthsOfPayments,
      currentForgivenessProgram,
      degree,
      employmentType,
      planInGov,
      planAsTeacher,
      oneTimeAdjustmentPayments,
      income,
      familySize,
      state,
    };
  }

  get hasErrorsInCalcs() {
    return this.hasErrorInCalcs;
  }

  get formattedCurrentPlanSummary() {
    // TODO: This can be removed so we just rely on currentPlanSummary directly
    // but would require changing code elsewhere because the keys are different
    const raw = this.currentPlanSummary;
    return this.formatPlanSummary(raw);
  }

  get formattedBestPlanSummary() {
    // TODO: This can be removed so we just rely on bestPlanSummary directly
    // but would require changing code elsewhere because the keys are different
    const raw = this.bestPlanSummary;
    return this.formatPlanSummary(raw);
  }

  get nextSteps() {
    return this.bestPlanNextSteps;
  }

  get bestPlanTableRowSummary() {
    return this.bestPlanNextSteps?.map((step) => step?.title).join(' - ');
  }

  get bestPlanHeaderSummary() {
    return this.bestPlanTableHeadings;
  }

  get currentPlanHeaderSummary() {
    return this.currentPlanTableHeadings;
  }

  get estimatedMonthlyPayment() {
    const monthlyCeiling = this.formattedBestPlanSummary?.monthlyPayments.sort(
      (a: number, b: number) => a - b
    )[0];
    const valueInCents = monthlyCeiling || 0;
    return valueInCents / 100;
  }

  get estimatedForgivenessAmount() {
    const remLoanCeiling = this.formattedBestPlanSummary?.remLoanBalance.sort((a, b) => a - b)[0];
    const valueInCents = remLoanCeiling || 0;
    return valueInCents / 100;
  }

  get estimatedForgivenessDate() {
    if (!this.formattedBestPlanSummary?.termMonths) {
      return '';
    }
    const termMonthsCeiling = this.formattedBestPlanSummary?.termMonths.sort(
      (a: number, b: number) => a - b
    )[0] as number;
    const currentYear = new Date().getFullYear();
    const approxYearsToRepay = Math.ceil(termMonthsCeiling / 12);
    return currentYear + approxYearsToRepay;
  }

  get estimatedMonthlySavings() {
    const bestPlanMonthlyCeiling = this.formattedBestPlanSummary?.monthlyPayments.sort(
      (a: number, b: number) => a - b
    )[0] as number;
    const currentPlanMonthlyCeiling = this.formattedCurrentPlanSummary?.monthlyPayments.sort(
      (a, b) => a - b
    )[0] as number;

    return (currentPlanMonthlyCeiling - bestPlanMonthlyCeiling) / 100;
  }

  get downloadUrl() {
    const adapter = this.store.adapterFor('application');
    return adapter.buildURL('recommendation', this.id) + '.pdf';
  }

  get downloadFilename() {
    return `tuitionio-${this.requestingApp || 'estimator'}-results.pdf`;
  }

  formatPlanSummary(rawPlanSummary: Record<string, unknown>) {
    if (!rawPlanSummary) {
      return;
    }
    // These are all arrays, even if a single object inside
    return {
      payoffAmount: rawPlanSummary['payoff-amount'],
      termMonths: rawPlanSummary['term-months'] as number[],
      monthlyPayments: rawPlanSummary['monthly-payments'] as number[],
      totalInterestPaid: rawPlanSummary['total-interest-paid'],
      remLoanBalance: rawPlanSummary['estimated-amount-forgiven'] as number[],
    };
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    recommendation: RecommendationModel;
  }
}
