import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

type Positional = [string | undefined];

export interface RecognizeRouteHelperSignature {
  Args: {
    Positional: Positional;
  };
  Return: boolean;
}

/**
 * Returns a boolean if the router service recognizes the route name
 * This is helpful for hiding links that might be for employee app only
 *
 * {{recognize-route "authenticated.route.name.blah"}}
 *
 */
export default class RecognizeRoute extends Helper<RecognizeRouteHelperSignature> {
  @service declare router: RouterService;

  compute([routeName]: Positional) {
    if (!routeName) {
      return false;
    }
    try {
      const url = this.router.urlFor(routeName);
      this.router.recognize(url);
      return true;
    } catch (_error) {
      // router service will throw an error if url is unrecognizable
      return false;
    }
  }
}
