import { object, string, date, ref } from 'yup';

// This is not being used now but perhaps will be relevant when
// they decide to reintroduce the max/min lead time validation.
export const programInformationSchema = object({
  SCHOOL_INSTITUTION_NAME: string().required('Required'),
  PROGRAM_SPECIALIZATION: string().required('Required'),
  ESTIMATED_PROGRAM_BEGIN: date()
    .required('Program Begin Date Required')
    .test({
      name: 'is-date-valid',
      skipAbsent: true,
      test(value, context) {
        const { options } = context;
        // @ts-expect-error: will take some research on how to type this.
        const { maxLeadTime, minLeadTime } = options.context;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const selectedDate = new Date(value);
        const latestDateToApply = new Date(
          selectedDate.getTime() - minLeadTime * 24 * 60 * 60 * 1000
        );
        const earliestDateToApply = new Date(
          selectedDate.getTime() - maxLeadTime * 24 * 60 * 60 * 1000
        );

        if (today >= latestDateToApply) {
          return context.createError({
            message: `Last date to apply is ${minLeadTime} days before program begin date.`,
          });
        }
        if (today <= earliestDateToApply) {
          return context.createError({
            message: `Earliest date to apply is ${maxLeadTime} days before program begin date`,
          });
        }
        return true;
      },
    }),
  ESTIMATED_PROGRAM_COMPLETION: date()
    .required('Program End Date Required')
    .min(ref('ESTIMATED_PROGRAM_BEGIN'), 'Program End Date must be after begin date'),
});

export default null; // silence a false warning
