import { isEmpty as emberIsEmpty } from '@ember/utils';

type Falsy = false | 0 | '' | null | undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MaybeTruthy = any;
type TruthConvert<T> = T extends Falsy ? false : true;
type FirstTruthy<T extends MaybeTruthy[]> = T extends [infer Item]
  ? Item
  : T extends [infer Head, ...infer Tail]
    ? TruthConvert<Head> extends true
      ? Head
      : TruthConvert<Head> extends false
        ? FirstTruthy<Tail>
        : Exclude<Head, Falsy> | FirstTruthy<Tail>
    : undefined;
const truthConvert = (value: MaybeTruthy): boolean => {
  return Boolean(value);
};

export const or = <T extends MaybeTruthy[]>(...args: T): FirstTruthy<T> => {
  for (let i = 0, len = args.length; i < len; i++) {
    if (truthConvert(args[i]) === true) {
      return args[i];
    }
  }
  return args[args.length - 1];
};

export const and = <A, B>(a?: A, b?: B): A | B => (a && b) as A | B;
export const not = <A>(a?: A): boolean => !a;
// @ts-expect-error: We want to allow user to pass in different types even though it will be false.
export const eq = <A, B>(a: A, b: B): boolean => a === b;
export const notEq = <A>(a: A, b: A): boolean => a !== b;
export const gt = (a: unknown, b: unknown): boolean => Number(a) > Number(b);
export const gte = (a: unknown, b: unknown): boolean => Number(a) >= Number(b);
export const lt = (a: unknown, b: unknown): boolean => Number(a) < Number(b);
export const lte = (a: unknown, b: unknown): boolean => Number(a) <= Number(b);
export const isEmpty = emberIsEmpty;

export type Intent = 'primary' | 'success' | 'info' | 'warning' | 'error' | 'hidden';
