import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

// https://github.com/josemarluedke/frontile/blob/main/packages/overlays/src/components/drawer.gts#L75
type DrawerSignature = {
  // passthroughs
  isOpen: boolean;
  allowCloseButton?: boolean;
  onClose?: () => void;
  // defaults
  placement?: 'top' | 'bottom' | 'left' | 'right';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
};

type TargetArgs = Pick<DrawerSignature, 'isOpen' | 'allowCloseButton' | 'onClose' | 'size'>;

export default class DrawerService extends Service {
  @tracked targetElement?: HTMLElement;

  // passthrough Frontile Drawer component args
  @tracked isOpen: boolean = false;
  @tracked size: DrawerSignature['size'] = 'lg';
  @tracked allowCloseButton?: boolean = true;
  @tracked declare onClose?: () => void;

  @action
  registerTargetElement(element: HTMLElement): void {
    this.targetElement = element;
  }

  @action
  resetTargetElement(): void {
    this.targetElement = undefined;
  }

  @action
  registerTargetArgs(values: TargetArgs): void {
    this.size = values.size;
    this.isOpen = values.isOpen;
    this.allowCloseButton = values.allowCloseButton;
    this.onClose = values.onClose;
  }

  @action
  resetTargetArgs(): void {
    this.size = undefined;
    this.isOpen = false;
    this.allowCloseButton = undefined;
    this.onClose = undefined;
    this.size = 'lg';
  }

  get defaultArgs(): Omit<DrawerSignature, 'isOpen'> {
    return {
      placement: 'right',
    };
  }
}
