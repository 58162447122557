import Model, { attr, belongsTo } from '@ember-data/model';
import type ObservableDocumentModel from './observable-document';

// const MODEL_NAME = 'observable-document-log';
export type LogSource = [
  'dispatch',
  'process',
  'validation_failed',
  'content_extracted',
  'dispute',
][number];

export default class ObservableDocumentLogModel extends Model {
  /*************************
   **  Attributes         **
   *************************/
  // TODO: type this
  @attr declare detail: { note: string; changes: { [key: string]: unknown } };
  // @attr declare logSource: (typeof LOG_SOURCE)[number];

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('observable-document', { async: false, inverse: 'observableDocumentLogs' })
  declare observableDocument: ObservableDocumentModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    observableDocumentLog: ObservableDocumentLogModel;
  }
}
