import { attr, belongsTo, hasMany } from '@ember-data/model';
import type Store from '@ember-data/store';
import { service } from '@ember/service';

import CommentableModel from './commentable.ts';
import type NotificationHistoryModel from './notification-history.ts';
import type CompanyModel from './company.ts';
import type PlanModel from './plan.ts';
import type UserModel from './user.ts';
import type PersonModel from './person.ts';
import type WorkspaceModel from './workspace.ts';
import type PslfProfileModel from './pslf-profile.ts';
import type AccountModel from './account.ts';
import type FamilyModel from './family.ts';
import type PhoneNumberModel from './phone-number.ts';
import type PostalAddressModel from './postal-address.ts';
import type PslfAssetModel from './pslf-asset.ts';
import type PslfFormModel from './pslf-form.ts';
import type ScholarshipModel from './scholarship.ts';
import type StrategyFinderResultModel from './strategy-finder-result.ts';
import type EmployeeVersionModel from './employee-version.ts';
import type TagModel from './tag.ts';
import type TasProgramInstanceModel from './tas-program-instance.ts';
import type RoleViewModel from './role-view.ts';
import type GenericRelationshipModel from './generic-relationship.ts';
import type TasParticipantModel from './tas-participant.ts';
import type MatchParticipantModel from './match-participant.ts';
import type InviteModel from './invite.ts';
import type DependentModel from './dependent.ts';
import type TasEligibilityModel from './tas-eligibility.ts';

export type CustomAttributes = {
  'agent-name'?: string;
  'agent-phone'?: string;
  'agent-email'?: string;
  'agent-website'?: string;
  'agent-schedule'?: string;
  'agent-license'?: string;
  'agency-name'?: string;
  'agent-address-line1'?: string;
  'agent-address-line2'?: string;
  'agent-address-city'?: string;
  'agent-address-state'?: string;
  'agent-address-zip-code'?: string;
  fein?: string;
  'hours-worked-per-week'?: number;
  'school-district'?: string;
  'school-city'?: string;
  'school-state'?: string;
  'school-building'?: string;
  'schoolbldg-zipcode'?: string;
  employer?: string;
  raw?: {
    [key: string]: string;
  };
};

type EmployeeStatistics = {
  user: {
    total_accounts: number;
    total_loans: number;
  };
};

export default class EmployeeModel extends CommentableModel {
  @service declare store: typeof Store;

  @attr declare addressLine1: string;
  @attr declare addressLine2: string;
  @attr declare birthYear: string;
  @attr declare city: string;
  @attr declare contributionAmount: string;
  @attr declare costCenter: string;
  @attr declare country: string;
  @attr declare currency: string;
  @attr declare customAttributes: CustomAttributes;
  @attr declare department: string;
  @attr declare eligibilityEndDate: string;
  @attr declare eligibilityStartDate: string;
  @attr declare email: string;
  @attr declare employmentEndDate: string;
  @attr declare employmentStartDate: string;
  @attr declare employmentType: string;
  @attr declare firstName: string;
  @attr declare isActive: boolean;
  // @attr lastFourSSn;
  @attr declare lastName: string;
  @attr declare payrollId: string;
  @attr declare phoneNumber: string;
  @attr declare postalCode: string;
  @attr declare ratePeriod: string;
  // @attr region;
  @attr declare participationStatus: string;
  @attr declare userCreatedAt: string;
  @attr declare userUpdatedAt: string;
  @attr declare salary: string;
  @attr declare state: string;
  // @attr status; // Manas wants to move away from this since it is not always accurate
  @attr declare suffix: string;
  @attr declare title: string;
  // @attr tuitionAssistance;
  @attr declare userRoleId: string;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;
  @attr declare deactivatedOn: string;
  @attr declare inviteLink: string;
  @attr declare statistics: EmployeeStatistics;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('company', { async: false, inverse: null })
  declare company: CompanyModel;
  @belongsTo('plan', { async: false, inverse: 'employees' })
  declare plan: PlanModel;
  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;
  @belongsTo('person', { async: false, inverse: 'employees' })
  declare person: PersonModel;
  @belongsTo('workspace', { async: false, inverse: null })
  declare workspace: WorkspaceModel;
  @belongsTo('pslf-profile', { async: false, inverse: 'employee' })
  declare pslfProfile: PslfProfileModel;
  @belongsTo('tas-participant', { async: false, inverse: 'employee' })
  declare tasParticipant: TasParticipantModel;
  @belongsTo('match-participant', { async: false, inverse: 'employee' })
  declare matchParticipant: MatchParticipantModel;
  @hasMany('account', { async: false, inverse: null })
  declare accounts: AccountModel[];
  @hasMany('family', { async: false, inverse: null })
  declare families: FamilyModel[];
  @hasMany('dependent', { async: false, inverse: null })
  declare dependents: DependentModel[];
  @hasMany('notification-history', { async: false, inverse: null })
  declare notificationHistories: NotificationHistoryModel[];
  @hasMany('phone-number', { async: false, inverse: null })
  declare phoneNumbers: PhoneNumberModel[];
  @hasMany('postal-address', { async: false, inverse: null })
  declare postalAddresses: PostalAddressModel[];
  @hasMany('pslf-asset', { async: false, inverse: 'employees' })
  declare pslfAssets: PslfAssetModel[];
  @hasMany('pslf-form', { async: false, inverse: null })
  declare pslfForms: PslfFormModel[];
  @hasMany('scholarship', { async: false, inverse: null })
  declare scholarships: ScholarshipModel[];
  @hasMany('strategy-finder-result', { async: false, inverse: 'employees' })
  declare strategyFinderResults: StrategyFinderResultModel[];
  @hasMany('employee-version', { async: false, inverse: null })
  declare versions: EmployeeVersionModel[];
  @hasMany('tas-program-instance', { async: false, inverse: 'employee' })
  declare tasProgramInstances: TasProgramInstanceModel[];
  @hasMany('role-view', { async: false, inverse: null })
  declare roleViews: RoleViewModel[];
  @hasMany('generic-relationship', { async: false, inverse: null })
  declare jurisdictiveRelationships: GenericRelationshipModel[];
  // @hasMany('subjected-relationship', { async: false, inverse: null })
  // declare subjectedRelationships: GenericRelationshipModel[];
  @hasMany('invite', { async: false, inverse: null })
  declare invites: InviteModel[];
  @hasMany('tag', {
    async: false,
    inverse: 'taggable',
    as: 'taggable',
  })
  declare tags: TagModel[];
  @hasMany('tas-eligibility', { async: false, inverse: 'employee' })
  declare tasEligibilities: TasEligibilityModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get activeAccounts() {
    return this.accounts?.filter((e) => e.isActive) || [];
  }

  get activeLoans() {
    return this.activeAccounts.map((account) => account.activeLoans).flat();
  }

  get fullName() {
    return [this.firstName, this.lastName].join(' ');
  }

  get primaryPhoneNumber() {
    const numbers = this.phoneNumbers;
    return numbers.find((e) => e.phoneNumberType === 'MOBILE') || this.phoneNumbers[0];
  }

  get totalLoanBalance() {
    return this.activeAccounts.reduce((sum, account) => {
      return (sum += account.loanTotal);
    }, 0);
  }

  get needsToLinkLoans() {
    return !!this.person?.needsToLinkLoans;
  }

  get needsToAnswerWhyNoLinkedLoans() {
    return !!this.person?.needsToAnswerWhyNoLinkedLoans;
  }

  get secureEnrollmentInProgress() {
    return !!this.matchParticipant?.isEnrolling;
  }

  get secureEnrollmentComplete() {
    return !!this.matchParticipant?.isEnrolled;
  }

  get secureBenefitStartDate() {
    return this.matchParticipant?.matchPlan?.benefitStartDate;
  }

  get customAttributesGroupedByPrimitive() {
    const flat: Record<string, unknown> = {};
    const nested: Record<string, unknown> = {};
    const obj = this.customAttributes;

    for (const key in obj) {
      // @ts-expect-error: return to this
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        // @ts-expect-error: return to this
        nested[key] = obj[key];
      } else {
        // @ts-expect-error: return to this
        flat[key] = obj[key];
      }
    }
    return { flat, nested };
  }

  get isTermVerified() {
    return (this.tags || []).some((tag) => tag.name === 'Term Verified');
  }

  get hasDependents() {
    return !!this.dependents.length;
  }

  async terminateEmployee() {
    return this.store.adapterFor('employee').terminateEmployee(this);
  }

  async reinstateEmployee() {
    return this.store.adapterFor('employee').reinstateEmployee(this);
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    employee: EmployeeModel;
  }
}
