import type EmployeeModel from '../../models/employee.ts';

export function doEmployeesExistInApproverList(
  employeesToMatch: EmployeeModel[] = [],
  approverEmployeeIds: Set<number>
) {
  if (approverEmployeeIds.size === 0) {
    return false;
  }

  const employeeIds: number[] = employeesToMatch.map((e) => parseInt(e.id));

  return employeeIds.some((id) => approverEmployeeIds.has(id));
}

export default null; // silence a false warning
