import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type EmployeeModel from './employee.ts';
import type CompanyModel from './company.ts';

export default class PlanModel extends Model {
  @attr declare companyKey: string;
  @attr declare description: string;
  @attr declare name: string;
  @attr declare currency: string;
  @attr declare cadence: string;
  @attr declare milestoneDate: string;
  @attr declare waitingPeriod: number;
  @attr declare isMinMonthly: boolean;
  @attr declare stats: Record<string, unknown>[];
  @attr declare tiers: Record<string, unknown>[];
  @attr declare lifetimeLimit: number;
  @attr declare annualLimit: number;
  @attr declare checkObservations: boolean;
  @attr declare useAggregation: boolean;
  @attr declare createdAt: string;
  @attr declare checkParentPlusLoans: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @hasMany('employee', { async: false, inverse: 'plan' })
  declare employees: EmployeeModel[];
  @belongsTo('company', { async: false, inverse: 'plans' })
  declare company: CompanyModel;

  /**************************
   **  Computed Properties **
   **************************/

  get isWellness() {
    return this.cadence === 'NEVER';
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    plan: PlanModel;
  }
}
