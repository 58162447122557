import Model, { attr, belongsTo } from '@ember-data/model';
import type TASProgramTemplateModel from './tas-program-template.ts';

export default class TasFieldOptionModel extends Model {
  @attr declare fieldName: string;
  @attr declare value: string;
  @attr declare label: string;
  @attr declare valueType: 'string' | 'integer' | 'float' | 'boolean';
  @attr declare ordinal: number;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('tas-program-template', { async: false, inverse: 'tasFieldOptions' })
  declare tasProgramTemplate: TASProgramTemplateModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-field-option': TasFieldOptionModel;
  }
}
