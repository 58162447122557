import Model, { attr, belongsTo } from '@ember-data/model';

export default class GenericRelationshipModel extends Model {
  @attr declare relationshipType: string;
  @attr declare governableType: string;
  @attr declare governableId: string;
  @attr declare governorType: string;
  @attr declare governorId: string;
  @attr declare enum: object;
  @attr declare createdAt: string;
  @attr declare createdBy: string;
  @attr declare updatedAt: string;
  @attr declare updatedBy: string;
  @attr declare isActive: boolean;
  @attr declare expiresAfter: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('generic-relationship', { async: false, inverse: null })
  declare delegateOf: GenericRelationshipModel;

  @belongsTo('generic-relationship', { async: false, inverse: null })
  declare governor: GenericRelationshipModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'jurisdictive-relationship': GenericRelationshipModel;
  }
}
