import Model, { attr, belongsTo } from '@ember-data/model';
import type CompanyModel from './company.ts';
import type EmployeeModel from './employee.ts';

export default class ContributionModel extends Model {
  @attr declare amount: number;
  @attr declare balance: number;
  @attr declare status: string;
  @attr declare type: string;
  @attr declare createdAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('company', { async: false, inverse: null })
  declare company: CompanyModel;
  @belongsTo('employee', { async: false, inverse: null })
  declare employee: EmployeeModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    contribution: ContributionModel;
  }
}
