import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from './user.ts';

export default class PhoneNumberModel extends Model {
  @attr declare number: string;
  @attr declare contactType: string;
  @attr declare phoneNumberType: string;
  @attr declare isActive: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'phone-number': PhoneNumberModel;
  }
}
