import Model, { attr } from '@ember-data/model';

export default class TasUnaccreditedInstitutionModel extends Model {
  @attr declare address: string;
  @attr declare locationName: string;
  @attr declare locationType: string;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-unaccredited-institution': TasUnaccreditedInstitutionModel;
  }
}
