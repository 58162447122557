import { modifier } from 'ember-modifier';
import Inputmask from 'inputmask';

type Named = Inputmask.Options;

interface InputmaskModifier {
  Args: {
    // TODO: find out what the named args are.
    Named: Named;
    Positional: undefined;
  };
  Element: HTMLElement;
}

export default modifier<InputmaskModifier>(function inputmask(element, _positional, named) {
  new Inputmask(named).mask(element);
});
