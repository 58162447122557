import { modifier } from 'ember-modifier';
import { resolveTemplateLiteralVariables } from '../utils/resolve-template-literal-variables.ts';
import type { ModifierLike } from '@glint/template';

type PropertySpec = {
  property: string;
  values: string[];
};

type StateType = number | boolean | string;

const parsePropertySpecs = (propertySpecs: string, element: HTMLElement): PropertySpec[] =>
  propertySpecs
    .split(';')
    .map((spec): PropertySpec | undefined => {
      if (!spec) {
        return undefined;
      }

      const [property, values] = spec.trim().split(':');

      if (!property || !values) {
        console.warn(
          `Invalid property spec: ${spec}. ` +
            `Must be in the form 'property:value1[,value2[,value3]]`
        );

        return undefined;
      }

      return {
        property,
        values: values
          .split(',')
          .map((value) => resolveTemplateLiteralVariables(value.trim(), element)),
      };
    })
    .filter((item): item is PropertySpec => item !== undefined && item !== null);

const normalizeTime = (time: string | number): string =>
  typeof time === 'number' ? `${time}ms` : time;

const elementSpecs = new WeakMap<HTMLElement, PropertySpec[]>();

interface CssStateTransitionModifier extends ModifierLike {
  Args: {
    Positional: [StateType, string, string | number, string?, (string | number)?];
  };
  Element: HTMLElement;
}

export default modifier<CssStateTransitionModifier>(function CssStateTransition(
  element,
  [state, propertySpecs, duration, timingFunction, delay]
) {
  const index: number = typeof state === 'number' ? state : state ? 1 : 0;
  const specs: PropertySpec[] =
    elementSpecs.get(element) ?? parsePropertySpecs(propertySpecs, element);
  const properties: string[] = specs.map((spec) => spec.property);

  if (propertySpecs.indexOf('${') !== -1) {
    elementSpecs.set(element, specs);
  }

  element.style.transitionProperty = properties.join(',');
  if (duration === 0 || duration) {
    element.style.transitionDuration = normalizeTime(duration) ?? '150ms';
  }
  element.style.transitionTimingFunction = timingFunction ?? 'cubic-bezier(0.4, 0, 0.2, 1)';

  if (delay) {
    element.style.transitionDelay = normalizeTime(delay);
  }

  for (const spec of specs) {
    // @ts-expect-error: Even the robot says `any` is probably fine.
    element.style[spec.property] = spec.values[index];
  }
});
