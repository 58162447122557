import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';

export interface DigitalAssetDetailSignature {
  bucket: string;
  uuid: string;
  filename: string;
  name: string;
  key: string;
}

export default class DigitalAssetsService extends Service {
  @service declare store: typeof Store;

  /**
   *
   * @param {string} modelName - the model name for the asset type, ex. 'publicAsset'
   * @param {object} details - the uploaded file details returned from S3
   * @returns {Model} - returns a model of the asset type provided in CREATED state (i.e. NOT saved)
   */
  @action
  createAssetFromS3Details(modelName: string, details: DigitalAssetDetailSignature) {
    const assetType = modelName || 'digitalAsset';
    const asset = this.store.createRecord(assetType, {
      bucket: details.bucket,
      uuid: details.uuid,
      filename: details.filename,
      name: details.name,
      fullPath: details.key,
    });
    return asset;
  }
}
