import { attr, belongsTo } from '@ember-data/model';
import DigitalAsset from './digital-asset.ts';
import type ObservableDocument from './observable-document.ts';

export default class ObservableAssetModel extends DigitalAsset {
  /*************************
   **  Attributes         **
   *************************/
  @attr declare description: string;
  @attr declare contentType: 'application/pdf' | 'application/txt';
  @attr declare classification: string;
  @attr declare externalUrl: string;

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('observable-document', { async: false, inverse: 'observableAsset' })
  declare observableDocument: ObservableDocument;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    observableAsset: ObservableAssetModel;
  }
}
