import type Intl from 'ember-intl/services/intl';

export interface LookupTranslationOptions extends Record<string, unknown> {
  defaultMessage?: string;
}

/**
 * Return a translation by searching the given translation keys for the first
 * existing translation. If no translation is found and `defaultMessage` is
 * specified in the `options`, it is returned; otherwise a message about the
 * missing keys is returned.
 *
 * @param intl - The internationalization service used for translations
 * @param keys - The keys to search for translations
 * @param options - The options to pass to the translation service
 *
 * @returns A translation or a message about the missing keys
 */
export function lookupTranslation(
  intl: Intl,
  keys: string[],
  options?: LookupTranslationOptions
): string {
  const exists = keys.find((key) => intl.exists(key));

  if (!exists) {
    return (
      options?.defaultMessage ??
      `Missing translation at any of "${keys.join('", "')}" for locale "${intl.primaryLocale}"`
    );
  }

  return intl.t(keys.shift()!, {
    ...options,
    // @ts-expect-error: not fully sure why we need this utility
    default: keys,
  });
}

export default null; // silence a false warning
