import { modifier } from 'ember-modifier';

interface EmbedCalendlyModifier {
  Args: {
    Positional: [string, { firstName: string; lastName: string }, OnErrorEventHandler];
  };
  Element: HTMLElement;
}

export default modifier<EmbedCalendlyModifier>((element, [url, prefill, onError]) => {
  const tagId = 'calendly-embed';
  const script = document.createElement('script');
  script.src = 'https://assets.calendly.com/assets/external/widget.js';
  script.id = tagId;
  script.onerror = onError;
  script.onload = () => {
    // @ts-expect-error: should we import this versus a global?
    window.Calendly.initInlineWidget({
      url,
      parentElement: element,
      prefill,
    });
  };

  document.getElementsByTagName('head')?.[0]?.appendChild(script);
  return () => {
    document.getElementById(tagId)?.remove();
  };
});
