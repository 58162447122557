import Model, { attr } from '@ember-data/model';

type Whodunnit = {
  user: {
    id: string;
    person_id: string;
    first_name: string;
    last_name: string;
  };
};

export default class VersionModel extends Model {
  @attr declare itemType: string;
  @attr declare event: string;
  @attr declare objectChanges: Record<string, unknown>;
  @attr declare createdAt: string;
  @attr declare changelog: string;
  @attr declare whodunnit: Whodunnit;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    version: VersionModel;
  }
}
