
import { importSync as i } from '@embroider/macros';
let d = window.define;
import "@frontile/buttons/-embroider-implicit-modules.js";
import "@frontile/changeset-form/-embroider-implicit-modules.js";
import "@frontile/collections/-embroider-implicit-modules.js";
import "@frontile/forms/-embroider-implicit-modules.js";
import "@frontile/overlays/-embroider-implicit-modules.js";
import "@frontile/theme/-embroider-implicit-modules.js";
import "@frontile/utilities/-embroider-implicit-modules.js";
import "@frontile/status/-embroider-implicit-modules.js";
import "ember-file-upload/-embroider-implicit-modules.js";
