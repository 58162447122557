import Service, { service } from '@ember/service';
import type CableService from './cable.ts';

export type Appearance = {
  name: string;
  personId: string;
  documentId: string;
  type: string;
};

export default class AppearanceService extends Service {
  @service declare cable: CableService;
  // TODO: Figure out how to type this
  subscription: ReturnType<typeof this.cable.consumer>['subscriptions']['create'] | null = null;

  // TODO: move connection logic to cable service and use environment variables
  async connect(apiHost: string, apiKey: string, token: string) {
    this.cable.connect(apiHost, apiKey, token);
  }

  async subscribe(
    type: string,
    documentId: string,
    onUpdate: (msg: Record<string, Appearance>) => void
  ) {
    this.subscription = this.cable.consumer.subscriptions.create(
      {
        channel: 'AppearanceChannel',
        type,
        document_id: documentId,
      },
      {
        received: (msg: Record<string, Appearance>) => {
          onUpdate(msg);
          console.log(msg);
        },
      }
    );
  }

  async unsubscribe(type: string, documentId: string) {
    this.subscription.unsubscribe({
      channel: 'AppearanceChannel',
      type,
      document_id: documentId,
    });
    this.subscription = null;
  }

  // async broadcast(msg: Record<string, unknown>) {
  //   this.subscription?.perform('appear', { test: 321 });
  // }
}
