import { helper } from '@ember/component/helper';
import type TasProgramTemplate from '../models/tas-program-template.ts';
import type { TemplateFieldName } from '../utils/tuition-assistance/fields.ts';

/**
  {{program-label-for "SCHOOL_INSTITUTION_NAME" programTemplate=@tasProgramTemplate}}
 */
export default helper(function programLabelFor(
  [field]: [TemplateFieldName],
  { programTemplate }: { programTemplate: TasProgramTemplate }
) {
  if (!field || !programTemplate) {
    return '';
  }
  return (programTemplate.fields?.[field]?.label as string) || field;
});
