import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import type AggregationLoanModel from './aggregation-loan.ts';
import type AggregationAccountChangeLogModel from './aggregation-account-change-log.ts';
import type AggregationInstitutionModel from './aggregation-institution.ts';

export default class AggregationAccountModel extends Model {
  @attr declare currency: string;

  /*************************
   **  Relationships      **
   *************************/

  @hasMany('aggregation-loan', { async: false, inverse: 'aggregationAccount' })
  declare aggregationLoans: AggregationLoanModel[];

  @hasMany('aggregation-account-change-log', {
    async: false,
    inverse: 'aggregationAccount',
  })
  declare aggregationAccountChangeLogs: AggregationAccountChangeLogModel[];

  @belongsTo('aggregation-institution', { async: false, inverse: null })
  declare aggregationInstitution: AggregationInstitutionModel;

  /**************************
   **  Computed Properties **
   **************************/
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'aggregation-account': AggregationAccountModel;
  }
}
