import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type EmployeeModel from './employee.ts';
import type MatchPlanModel from './match-plan.ts';
import type MatchPaymentModel from './match-payment.ts';
import type EmailAddressModel from './email-address.ts';
import type PersonModel from './person.ts';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type { MatchParticipantInsights } from '../types/secure-your-future.ts';
import { isNullish } from '../utils/is-nullish.ts';

type SyfEnrollmentState =
  | 'SYF.EnrollmentState.NOT_STARTED'
  | 'SYF.EnrollmentState.DRAFT'
  | 'SYF.EnrollmentState.COMPLETE'
  | 'SYF.EnrollmentState.MISSING_INFORMATION';

type SyfTransitionLogItem = {
  event: string;
  from: SyfEnrollmentState;
  reason: string;
  timestamp: string;
  to: SyfEnrollmentState;
};

export default class MatchParticipantModel extends Model {
  @attr declare reportedSalary: number;
  @attr declare reportedSalaryDate: string;
  @attr declare reportedMonthlyPayment: number;
  @attr declare enrollmentState: SyfEnrollmentState;
  @attr declare transitionLog: Array<SyfTransitionLogItem>;

  @tracked insights?: MatchParticipantInsights;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('employee', { async: false, inverse: 'employee' })
  declare employee: EmployeeModel;
  @belongsTo('person', { async: false, inverse: null })
  declare person: PersonModel;
  @belongsTo('match-plan', { async: false, inverse: null })
  declare matchPlan: MatchPlanModel;
  @belongsTo('email-address', { async: false, inverse: null })
  declare emailAddress: EmailAddressModel;
  @hasMany('match-payment', { async: false, inverse: 'matchParticipant' })
  declare matchPayments: MatchPaymentModel[];

  get isEnrolling() {
    return this.pendingEnrollmentStates.has(this.enrollmentState);
  }

  get isEnrolled() {
    return this.completedEnrollmentStates.has(this.enrollmentState);
  }

  get enrollmentDate(): string | undefined {
    const enrolledStates = [
      'SYF.EnrollmentState.COMPLETE',
      'SYF.EnrollmentState.MISSING_INFORMATION',
    ];
    const logItem = this.transitionLog.find((logItem) => enrolledStates.includes(logItem.to));
    return logItem?.timestamp;
  }

  get reportedSalaryInDollars() {
    return this.reportedSalary / 100;
  }

  get reportedMonthlyPaymentInDollars() {
    return this.reportedMonthlyPayment / 100;
  }

  get hasDataForInsights() {
    return !(isNullish(this.reportedSalary) || isNullish(this.reportedMonthlyPayment));
  }

  preEnrollmentStates: Set<SyfEnrollmentState> = new Set(['SYF.EnrollmentState.NOT_STARTED']);

  pendingEnrollmentStates: Set<SyfEnrollmentState> = new Set(['SYF.EnrollmentState.DRAFT']);

  completedEnrollmentStates: Set<SyfEnrollmentState> = new Set([
    'SYF.EnrollmentState.MISSING_INFORMATION',
    'SYF.EnrollmentState.COMPLETE',
  ]);

  @action
  async beginEnrollment() {
    await this.store.adapterFor('match-participant').beginEnrollment(this);
    return this.reload();
  }

  @action
  async activateMatchParticipant() {
    await this.store.adapterFor('match-participant').activateMatchParticipant(this);
    return this.reload();
  }

  @action
  async fetchInsights() {
    if (!this.hasDataForInsights) {
      return;
    }
    const insights = await this.store.adapterFor('match-participant').getInsights(this);
    this.insights = insights;
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'match-participant': MatchParticipantModel;
  }
}
