import type TasProgramTemplateModel from '../../models/tas-program-template.ts';
import type { DynamicEligibility, PeriodOfEligibility } from '../../types/tuition-assistance.ts';

export function filterEligibleTemplates(
  templates: TasProgramTemplateModel[],
  dynamicEligibility: DynamicEligibility,
  programEligibility: PeriodOfEligibility
) {
  const today = new Date();

  return templates.filter((template) => {
    const code = template.code;

    // Check if the template code is dynamically eligible (should be true)
    if (!dynamicEligibility[code]) {
      return false;
    }

    const periods = programEligibility[code];

    // Ensure periods exist and are not empty
    if (!periods || periods.length === 0) {
      return false;
    }

    // Get the last period, ensuring it is defined
    const lastPeriod = periods[periods.length - 1];
    if (!lastPeriod || lastPeriod.length < 2) {
      return false;
    }

    const startDate = new Date(lastPeriod[0]!);
    const endDate = new Date(lastPeriod[1]!);

    // Ensure startDate and endDate are valid dates
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return false;
    }

    // Check if today's date is within the last period
    return today >= startDate && today <= endDate;
  });
}

export default null; // silence a false warning
