import { modifier } from 'ember-modifier';

interface EmbedPdfModifier {
  Args: {
    Positional: [string];
  };
  Element: HTMLElement;
}

/**
 * <div {{embed-pdf @url}} />
 */
export default modifier<EmbedPdfModifier>((element, [url]) => {
  if (!url) {
    return;
  }
  const parent = element.parentElement;
  if (!parent) {
    console.error('embed-pdf modifier must be used on an element with a parent');
    return;
  }

  const bounds = parent.getBoundingClientRect();
  const width = `${bounds.width}px`;
  const height = `${bounds.height}px`;

  element.innerHTML = `<embed id="embed-pdf" width="${width}" height="${height}" src="${url}"></embed>`;

  const resizeHandler = function () {
    const bounds = parent.getBoundingClientRect();
    const firstChild = element.firstChild;

    if (!firstChild) {
      console.error('embed-pdf modifier must be used on an element with a child');
      return;
    }
    // @ts-expect-error not sure how to fix this
    firstChild.width = `${bounds.width}px`;
    // @ts-expect-error not sure how to fix this
    firstChild.height = `${bounds.height}px`;
  };

  window.addEventListener('resize', resizeHandler);

  return () => window.removeEventListener('resize', resizeHandler);
});
