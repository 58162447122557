import Model, { attr, belongsTo } from '@ember-data/model';
import type TASApplicationModel from './tas-application.ts';
import type { TransitionLogSignature } from '../types/tuition-assistance.ts';

export default class TasAssistanceModel extends Model {
  @attr declare state: string;
  @attr declare amount: number;
  @attr declare fields: Record<string, unknown>;
  @attr declare transitionLog: TransitionLogSignature[];

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('tas-application', { async: false, inverse: 'tasApplication' })
  declare tasApplication: TASApplicationModel;

  get isSettled() {
    return this.state === 'TAS.AssistanceState.SETTLED';
  }

  get settledDate(): string | null {
    const settledTransition = this.transitionLog.find(
      (log: TransitionLogSignature) => log.to === 'TAS.AssistanceState.SETTLED'
    );

    return settledTransition
      ? ((settledTransition.reason?.payment?.date || settledTransition.timestamp) as string)
      : null;
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-assistance': TasAssistanceModel;
  }
}
