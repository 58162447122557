import { hasMany } from '@ember-data/model';
import DigitalAsset from './digital-asset.ts';
import type EmployeeModel from './employee.ts';
import type PslfProfileModel from './pslf-profile.ts';

export default class PslfAsset extends DigitalAsset {
  @hasMany('employee', { async: false, inverse: 'pslfAssets' })
  declare employees: EmployeeModel[];
  @hasMany('pslf-profile', { async: false, inverse: 'pslfAssets' })
  declare pslfProfiles: PslfProfileModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'pslf-asset': PslfAsset;
  }
}
