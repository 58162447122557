import Service from '@ember/service';
import { action } from '@ember/object';

interface EnvironmentConfigSignature {
  modulePrefix: string;
  environment: string;
  rootURL: string;
  locationType: string;
  historySupportMiddleware: boolean;
  EmberENV: {
    FEATURES: Record<string, unknown>;
    EXTEND_PROTOTYPES: Record<string, unknown>;
    APP: Record<string, unknown>;
  };
  tioEnvironment: string;
  disableDeprecations: boolean;
  featureFlags: Record<string, boolean>;
  datadog: { applicationId: string; clientToken: string };
}

export default class FeaturesService extends Service {
  ENV: EnvironmentConfigSignature | null = null;

  get tioEnvironment() {
    return this.ENV?.tioEnvironment || 'local';
  }

  get isAdminApp() {
    return this.ENV?.modulePrefix === 'tio-admin';
  }

  get isEmployeeApp() {
    return this.ENV?.modulePrefix === 'tio-employee';
  }

  get isAgentApp() {
    return this.ENV?.modulePrefix === 'tio-agent';
  }

  @action
  isEnabled(feature: string): boolean {
    if (!this.ENV) {
      throw 'ENV does not exist on FeaturesService. You must call `initWithEnvironment` from consuming app and pass the ENV.';
    }
    return this.ENV?.featureFlags?.[feature] || false;
  }

  @action
  isDisabled(feature: string): boolean {
    return !this.isEnabled(feature);
  }

  @action
  initWithEnvironment(ENV: EnvironmentConfigSignature) {
    this.ENV = ENV;
  }
}
