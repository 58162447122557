import { attr, belongsTo, hasMany } from '@ember-data/model';
import type PlanModel from './plan.ts';
import type AttachmentModel from './attachment.ts';
import type TasProgramTemplateModel from './tas-program-template.ts';
import type CompanySettingModel from './company-setting.ts';
import type PublicAssetModel from './public-asset.ts';
import type UserModel from './user.ts';
import type ConditionModel from './condition.ts';
import type EligibilityFileJobLogModel from './eligibility-file-job-log.ts';
import type MatchPlanModel from './match-plan.ts';
import type ScheduleModel from './schedule.ts';
import type TasGroupModel from './tas-group.ts';
import type CustomCompanyEmailTemplateModel from './custom-company-email-template.ts';
import CommentableModel from './commentable.ts';

export default class CompanyModel extends CommentableModel {
  @attr declare name: string;
  @attr declare launchDate: string;
  @attr declare programTitle: string;
  @attr declare legacyId: string;
  @attr declare isActive: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @hasMany('plan', { async: false, inverse: 'company' })
  declare plans: PlanModel[];
  @hasMany('attachment', { async: false, inverse: null })
  declare attachments: AttachmentModel[];
  @hasMany('tas-program-template', { async: false, inverse: 'company' })
  declare tasProgramTemplates: TasProgramTemplateModel[];
  @hasMany('eligibility-file-job-log', { async: false, inverse: null })
  declare eligibilityFileJobLogs: EligibilityFileJobLogModel[];
  @hasMany('condition', {
    async: false,
    inverse: 'conditionable',
    as: 'conditionable',
  })
  declare conditions: ConditionModel[];
  @hasMany('match-plan', { async: false, inverse: 'company' })
  declare matchPlans: MatchPlanModel[];
  @belongsTo('company-setting', { async: false, inverse: 'company' })
  declare companySetting: CompanySettingModel;
  @belongsTo('public-asset', { async: false, inverse: null })
  declare logo: PublicAssetModel;
  // The user that represents this company (every company is a user)
  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;
  @hasMany('schedule', {
    async: false,
    inverse: 'owner',
    as: 'owner',
  })
  declare schedules: ScheduleModel[];
  @hasMany('tas-group', { async: false, inverse: null })
  declare tasGroups: TasGroupModel[];
  @hasMany('custom-company-email-template', { async: false, inverse: 'company' })
  declare customCompanyEmailTemplates: CustomCompanyEmailTemplateModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    company: CompanyModel;
  }
}
