import { object, string, lazy, boolean } from 'yup';

import 'yup-phone-lite';

const feinRegExp = /^\d{2}-?\d{7}$/;

const pslfGroupFormSchema = object({
  employer: object().shape(
    {
      legalName: string().nullable().required('The Company name field is required'),
      fein: string()
        .nullable()
        .when('fein', (val) => {
          if (val?.length > 0) {
            return string().nullable().matches(feinRegExp, 'Invalid FEIN');
          } else {
            return string().nullable().notRequired();
          }
        }),
      website: string()
        // @ts-expect-error: look into how this is typed.
        .nullable(true)
        .when('website', (val) => {
          if (val?.length > 0) {
            // @ts-expect-error: look into how this is typed.
            return string().nullable(true).url(`The Website (if any) field is not a valid URL`);
          } else {
            // @ts-expect-error: look into how this is typed.
            return string().nullable(true).notRequired();
          }
        }),
      address: object().shape({
        addressLine1: string().nullable().required('Required'),
        city: string().nullable().required('Required'),
        state: string().nullable().required('Required'),
        postalCode: string()
          .nullable()
          .required('Required')
          .min(5, 'Invalid ZIP code')
          .max(5, 'Invalid ZIP code'),
      }),
      organizationOverride: object()
        .nullable()
        .shape({
          isGovernment: lazy((_, schema) => {
            if (schema.context.showOrganizationOverrideFields) {
              return boolean().required('Required');
            } else {
              return boolean().nullable().notRequired();
            }
          }),
          isTaxExempt: boolean().nullable().notRequired(),
          isNotForProfit: boolean().nullable().notRequired(),
          isPolitical: boolean().nullable().notRequired(),
        }),
    },
    [
      ['website', 'website'],
      ['fein', 'fein'],
    ]
  ),
  approver: object().shape({
    name: lazy((_, schema) => {
      if (schema.context.isRequiredEmployerDetail) {
        return string().nullable().required('Required');
      } else {
        return string().nullable().notRequired();
      }
    }),
    title: lazy((_, schema) => {
      if (schema.context.isRequiredEmployerDetail) {
        return string().nullable().required('Required');
      } else {
        return string().nullable().notRequired();
      }
    }),
    phoneNumber: lazy((value, schema) => {
      if (schema.context.isRequiredEmployerDetail || value?.length > 0) {
        return string()
          .nullable()
          .phone(
            'IN',
            "Number must begin with '+' followed by a valid country code and phone number"
          )
          .required('Invalid phone number');
      } else {
        return string().nullable().notRequired();
      }
    }),
    email: lazy((value, schema) => {
      if (schema.context.isRequiredEmployerDetail || value?.length > 0) {
        return string()
          .nullable()
          .required('The Email field must be a valid email')
          .email('The Email field must be a valid email');
      } else {
        return string().nullable().notRequired();
      }
    }),
  }),
});

export default pslfGroupFormSchema;
