function colorCodeToRGB(colorCode: string): number[] {
  colorCode = colorCode.slice(1);

  return [colorCode.slice(0, 2), colorCode.slice(2, 4), colorCode.slice(4, 6)].map((value) =>
    parseInt(value, 16)
  );
}

const sRGB = (colorBit: number): number =>
  colorBit <= 0.03928 ? colorBit / 12.92 : Math.pow((colorBit + 0.055) / 1.055, 2.4);

export function getLuminosity(colorCode: string): number {
  const [red = 0, green = 0, blue = 0] = colorCodeToRGB(colorCode);

  // Formula defined here: http://www.w3.org/TR/WCAG20/#relativeluminancedef
  return sRGB(red / 255) * 0.2126 + sRGB(green / 255) * 0.7152 + sRGB(blue / 255) * 0.0722;
}

export default null; // silence a false warning
