// @ts-expect-error: For some reason TS says this doesn't exist. But it was working as a js file.
import { debounce } from 'lodash-es';
import { modifier } from 'ember-modifier';
import { addListener, removeListener } from 'resize-detector';

/**
 * Calls the `callback` whenever `element` is resized.
 *
 * ## Options
 *
 * `debounce`
 * : The amount of time to wait before `callback` is called. If another resize
 *   event occurs while waiting, the time to wait is reset
 */

type CallbackFunction = (arg: HTMLElement) => void;
type Options = { debounce: number };

export default modifier(
  (element: HTMLElement, [callback]: [CallbackFunction], options: Options) => {
    const resized = debounce(callback, options?.debounce ?? 0);

    addListener(element, resized);

    return () => removeListener(element, resized);
  },
  // @ts-expect-error: There is an issue with modifiers not exposing this correctly.
  { eager: false }
);
