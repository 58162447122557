import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from './user.ts';

type EmailAddressContactType = 'PERSONAL' | 'PRIMARY' | 'SECONDARY';

export default class EmailAddressModel extends Model {
  @attr declare email: string;
  // delegated in the rails API resource to the ActiveRecord model
  // #type method; this maps to the type column in the DB and *not*
  // to the contact_type column, which is also present in the DB!
  @attr declare contactType: EmailAddressContactType;
  @attr declare isActive: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;

  get isPersonal() {
    return this.contactType === 'PERSONAL';
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'email-address': EmailAddressModel;
  }
}
