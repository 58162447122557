import Model, { attr, belongsTo } from '@ember-data/model';
import type FamilyModel from './family.ts';

export default class FamilyInviteModel extends Model {
  @attr declare inviteCodeTypeId: string;
  @attr declare isActive: boolean;
  @attr declare isValid: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('family', { async: false, inverse: 'family' })
  declare family: FamilyModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'family-invite': FamilyInviteModel;
  }
}
