import Service, { service } from '@ember/service';
import type CableService from './cable.ts';
import type { Appearance } from './appearance.ts';

// Basic path is document id, then an array of object of person_ids => appearances

export type MultiDocumentPersonAppearanceMap = {
  [key: string]: Appearance;
};

export type MultiDocumentAppearanceMap = {
  [key: string]: MultiDocumentPersonAppearanceMap[];
};

export default class MultiDocumentAppearanceService extends Service {
  @service declare cable: CableService;

  // TODO: Figure out how to type this
  subscription: ReturnType<typeof this.cable.consumer>['subscriptions']['create'] | null = null;

  // TODO: move connection logic to cable service and use environment variables
  async connect(apiHost: string, apiKey: string, token: string) {
    this.cable.connect(apiHost, apiKey, token);
  }

  async subscribe(
    type: string,
    documentIds: string[],
    onUpdate: (msg: MultiDocumentAppearanceMap) => void
  ) {
    this.subscription = this.cable.consumer.subscriptions.create(
      {
        channel: 'MultiDocumentAppearanceChannel',
        type,
        document_ids: documentIds,
      },
      {
        received: (msg: MultiDocumentAppearanceMap) => {
          onUpdate(msg);
          console.log(msg);
        },
      }
    );
  }

  async unsubscribe(type: string, documentIds: string[]) {
    // TODO: Can we subscribe to multiple types or document sets? And then do we unsubscribe all of them?
    this.subscription.unsubscribe({
      channel: 'MultiDocumentAppearanceChannel',
      type,
      document_ids: documentIds,
    });
    this.subscription = null;
  }
}
