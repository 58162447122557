import { hasMany } from '@ember-data/model';
import DigitalAsset from './digital-asset.ts';
import type TASProgramInstanceModel from './tas-program-instance.ts';
import type TASApplicationModel from './tas-application.ts';
import type TASCourseModel from './tas-course.ts';

export default class TASAssetModel extends DigitalAsset {
  /*************************
   **  Relationships      **
   *************************/

  @hasMany('tas-program-instance', { async: false, inverse: 'tasAssets' })
  declare tasProgramInstances: TASProgramInstanceModel[];
  @hasMany('tas-application', { async: false, inverse: 'tasAssets' })
  declare tasApplications: TASApplicationModel[];
  @hasMany('tas-course', { async: false, inverse: 'tasAssets' })
  declare tasCourses: TASCourseModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-asset': TASAssetModel;
  }
}
