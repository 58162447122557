import Model, { attr, belongsTo } from '@ember-data/model';
import type MessageModel from './message.ts';
import type PersonModel from './person.ts';

export default class MessageReceiptModel extends Model {
  @attr declare createdAt: string;
  @attr declare updatedAt: string;
  @attr declare personId: number;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('message', { async: false, inverse: 'messageReceipts' })
  declare message: MessageModel;

  @belongsTo('person', { async: false, inverse: 'person' })
  declare person: PersonModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'message-receipt': MessageReceiptModel;
  }
}
