import Model, { attr, hasMany } from '@ember-data/model';
import type AccountingTransactionModel from './accounting-transaction.ts';
import type AccountingInvoiceModel from './accounting-invoice.ts';
import type Store from '@ember-data/store';
import { service } from '@ember/service';

export default class AccountingPaymentModel extends Model {
  @service declare store: typeof Store;

  @attr declare paymentDate: string;
  @attr declare description: string;
  @attr declare state: string;
  @attr declare tag: string;
  @attr declare messageId: string;
  @attr declare paymentPath: string;
  @attr declare stats: Record<string, unknown>;

  /*************************
   **  Relationships      **
   *************************/

  @hasMany('accounting-transaction', { async: false, inverse: null })
  declare accountingTransactions: AccountingTransactionModel[];

  @hasMany('accounting-invoice', { async: false, inverse: null })
  declare accountingInvoices: AccountingInvoiceModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get isCreated() {
    return this.state === 'CREATED';
  }
}
declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'accounting-payment': AccountingPaymentModel;
  }
}
