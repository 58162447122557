import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountingPaymentModel from './accounting-payment.ts';
import type AccountingInvoiceModel from './accounting-invoice.ts';
import type EmployeeModel from './employee.ts';

export default class AccountingTransactionModel extends Model {
  @attr declare description: string;
  @attr declare state: string;
  @attr declare amount: number;
  @attr declare transactionDate: string;
  @attr declare isCheck: boolean;
  @attr declare checkNumber: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('accounting-payment', { async: false, inverse: null })
  declare accountingPayment: AccountingPaymentModel;

  @belongsTo('accounting-invoice', { async: false, inverse: null })
  declare accountingInvoice: AccountingInvoiceModel;

  @belongsTo('employee', { async: false, inverse: null })
  declare employee: EmployeeModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'accounting-transaction': AccountingTransactionModel;
  }
}
