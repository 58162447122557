import Helper from '@ember/component/helper';

type ScreenSizeName = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const queries: Record<ScreenSizeName, string> = {
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  '2xl': '(min-width: 1536px)',
};

type Positional = ScreenSizeName;

export interface ScreenIsSignature {
  Args: {
    Positional: [Positional];
  };
  Return: boolean;
}

/**
 * Returns true if the current screen size matches the media query associated with the passed size name.
 * Named queries follow Tailwind's mobile-first default screen names/sizes: https://tailwindcss.com/docs/screens
 * See available queries and their names in the `queries` object above.
 * Example usage:
 *
 *   {{#if (screen-is "sm")}}
 *     <p>You content will appear on all screen sizes larger than the breakpoint for small screen.</p>
 *   {{/if}}
 *
 * @param {array} positional - The positional parameters for this function
 * @param {string} positional[0] - A screen size name to match with a media query string
 *
 * @return {boolean} Returns a boolean representing if a MediaQueryList object matches the media query string associated with the size name
 */
export default class ScreenIs extends Helper<ScreenIsSignature> {
  mediaQueryList?: MediaQueryList | null = null;
  onChangeHandler?: ((event: MediaQueryListEvent) => void) | null = null;

  compute([positional]: [Positional]) {
    const mediaQueryString = queries[positional];

    if (!mediaQueryString) {
      console.warn(
        `Missing media query for key: "${positional}". ` +
          `Please check tio-common/src/helpers/screen-is.js for valid key names`
      );
      return false;
    }
    return this.addMatchMediaListener(mediaQueryString);
  }

  willDestroy() {
    this.removeMatchMediaListener();
  }

  addMatchMediaListener(mediaQueryString: string) {
    this.removeMatchMediaListener();

    this.onChangeHandler = (event: MediaQueryListEvent) => {
      // @ts-expect-error does recompute take args?
      this.recompute(event.matches);
    };

    this.mediaQueryList = window.matchMedia(mediaQueryString);

    this.mediaQueryList.addEventListener('change', this.onChangeHandler);

    return this.mediaQueryList.matches;
  }

  removeMatchMediaListener() {
    if (this.onChangeHandler) {
      this.mediaQueryList?.removeEventListener('change', this.onChangeHandler);
      this.mediaQueryList = null;
      this.onChangeHandler = null;
    }
  }
}
