import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

type Positional = [number | string | null | undefined];
type Named = { defaultValue?: string };

export interface FormatCentsToDollarsSignature {
  Args: {
    Positional: Positional;
    Named: Named;
  };
  Return: string | null;
}

/**
 * Takes a number or string that represents a dollar amount in cents and formats it as a dollar amount.
 * Optional accepts a default value to return if the value is null or undefined.
 *
 * {{format-cents-to-dollars @currentBalance defaultValue="N/A"}}
 *
 */
export default class FormatCentsToDollars extends Helper<FormatCentsToDollarsSignature> {
  @service declare intl: IntlService;

  compute([valueToFormat]: Positional, named: Named = {}) {
    const defaultVal = named.defaultValue || '';

    if (valueToFormat === null || valueToFormat === undefined) {
      return defaultVal;
    }

    const valueInDollars =
      (typeof valueToFormat === 'number' ? valueToFormat : parseFloat(valueToFormat)) / 100;

    if (isNaN(valueInDollars)) {
      return defaultVal;
    }
    try {
      return this.intl.formatNumber(valueInDollars, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } catch (e) {
      console.warn(`Error formatting ${valueToFormat} to dollars: ${e}`);
    }
    return defaultVal;
  }
}
