/**
 * Common list of keys to not disclose in most mediums (such as a log or a journal)
 */
const _purgeKeys: RegExp[] = [
  /account.*number/i,
  /answer/i,
  /e.*mail/i,
  /first.*name/i,
  /last.*name/i,
  /login/i,
  /mfa/i,
  /password/i,
  /secret/i,
  /ssn/i,
  /user.*name/i,
];

/**
 * Remove keys that may contain sensitive data
 *
 * @param dirty - what to cleanup
 * @param purgeWith - replace the values of matched keys with this value; if undefined deletes the key.
 * @param purgeKeys - customizable list of regular expressions to match keys against
 *
 * @returns The cleaned object with sensitive keys purged.
 */
export default function purify<T>(
  dirty: T,
  purgeWith: string,
  purgeKeys: RegExp[] = _purgeKeys
): T {
  let pure = dirty;

  switch (typeof pure) {
    case 'object':
      if (pure) {
        pure = { ...pure };
        for (const resultKey in pure) {
          let omitted = false;

          for (const pattern of purgeKeys) {
            if (resultKey.match(pattern)) {
              const typeless = pure as { [key: string]: unknown };

              if (purgeWith === undefined) {
                delete typeless[resultKey];
              } else {
                typeless[resultKey] = purgeWith;
              }

              omitted = true;
              break;
            }
          }

          if (!omitted) {
            // recurse for nested objects
            pure[resultKey] = purify(pure[resultKey], purgeWith, purgeKeys);
          }
        }
      }
      break;
    default:
      break;
  }

  return pure;
}
