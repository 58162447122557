import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type TasGroupModel from './tas-group.ts';
import type EmployeeModel from './employee.ts';
import type LimitModel from './limit.ts';
import type TASApplicationModel from './tas-application.ts';
import type TasAssistanceModel from './tas-assistance.ts';
import type TASProgramTemplateModel from './tas-program-template.ts';

export default class TasProgramLimitModel extends Model {
  @attr declare limitCoefficient: string;
  @attr declare employmentType: string;

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('limit', { async: false, inverse: null }) declare limit: LimitModel;
  @belongsTo('tas-program-template', {
    async: false,
    inverse: null,
  })
  declare tasProgramTemplate: TASProgramTemplateModel;
  @belongsTo('employee', { async: false, inverse: null })
  declare employee: EmployeeModel;
  @belongsTo('tas-group', { async: false, inverse: 'tasGroup' }) declare tasGroup: TasGroupModel;

  @hasMany('tas-application', { async: false, inverse: null })
  declare tasApplications: TASApplicationModel[];
  @hasMany('tas-assistance', { async: false, inverse: null })
  declare tasAssistances: TasAssistanceModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-program-limit': TasProgramLimitModel;
  }
}
