import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type PersonModel from './person.ts';
import type MessageableModel from './messageable.ts';
import type MessageReceiptModel from './message-receipt.ts';

export default class MessageModel extends Model {
  @attr declare content: string;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;
  @attr declare sender: string;
  @attr declare personId: number;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('person', { async: false, inverse: 'messages' })
  declare person: PersonModel;

  @belongsTo('messageable', {
    async: false,
    polymorphic: true,
    inverse: 'messages',
  })
  declare messageable: MessageableModel;

  @hasMany('message-receipt', { async: false, inverse: 'message' })
  declare messageReceipts: MessageReceiptModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    message: MessageModel;
  }
}
