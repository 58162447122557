import { helper } from '@ember/component/helper';

/**
 * This is not an exhaustive function and has no support for international numbers.
 * There will surely be edge cases needing coverage here. See integration test `format-phone-test.js`
 * - Julia 1/24/2022
 */
export default helper(function formatPhone(positional: [string] /*, named*/) {
  let string = positional[0];

  if (!string) {
    return '';
  }

  string = string.replace(/[-()\s]/g, '');

  if (string.startsWith('+1')) {
    string = string.slice(2, string.length);
  }

  if (string.startsWith('1') && string.length === 11) {
    string = string.slice(1, string.length);
  }

  const formatted = `(${string.slice(0, 3)}) ${string.slice(3, 6)}-${string.slice(6, 10)}`;
  return formatted;
});
