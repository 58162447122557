import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type CompanyModel from './company.ts';
import type InvoiceLineItemModel from './invoice-line-item.ts';

type Stats = {
  total: number;
  subtotal: number;
};

export default class InvoiceModel extends Model {
  @attr declare description: string;
  @attr declare invoiceType: string;
  @attr declare stats: Stats;
  @attr declare invoiceDate: string;
  @attr declare status: string;
  @attr declare purchaseOrderNumber: number;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('company', { async: false, inverse: null })
  declare company: CompanyModel;
  @hasMany('invoice-line-item', { async: false, inverse: 'invoice' })
  declare invoiceLineItems: InvoiceLineItemModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get canAddLineItems() {
    // return this.invoiceType === 'CONTRIBUTION' && this.canEditLineItems;

    // Legacy invoices are read only for now
    return false;
  }

  get canEditLineItems() {
    // return ['CREATED', 'APPROVED'].includes(this.status);

    // Legacy invoices are read only for now
    return false;
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    invoice: InvoiceModel;
  }
}
