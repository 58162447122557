import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from './user.ts';

export default class AnswerProfileModel extends Model {
  @attr declare providingApp: string;
  @attr declare academicDegree: string;
  @attr declare affordPayments: string;
  @attr declare currentForgivenessProgram: string;
  @attr declare currentWorkingOnForgiveness: string;
  @attr declare employmentType: string;
  @attr declare federalLoanTime: string;
  @attr declare householdIncome: number;
  @attr declare householdSize: number;
  @attr declare madeLimitedWaiverPayments: number;
  @attr declare madePayments: number;
  @attr declare payoffGoal: string;
  @attr declare planAsTeacher: string;
  @attr declare planInGovernment: string;
  @attr declare residenceState: string;

  @attr declare recertifiedIncomeAt: string;
  @attr declare consolidatedLoansAt: string;
  @attr declare changedRepaymentPlanAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;

  /**************************
   **  Computed Properties **
   **************************/

  get hasExpiredRecertification() {
    if (!this.recertifiedIncomeAt) {
      return false;
    }

    const approxTenMonthsInMilliseconds = 31556952000 * (5 / 6);
    const todayInMilliseconds = new Date().getTime();
    const recertifiedAtInMilliseconds = new Date(this.recertifiedIncomeAt).getTime();
    const elapsedMilliseconds = todayInMilliseconds - recertifiedAtInMilliseconds;
    return elapsedMilliseconds >= approxTenMonthsInMilliseconds;
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'answer-profile': AnswerProfileModel;
  }
}
