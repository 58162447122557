import { object, string, date, lazy, boolean, number } from 'yup';
import 'yup-phone-lite';

const ssnRegExp = /^\d{3}-\d{2}-\d{4}$/;
const feinRegExp = /^\d{2}-?\d{7}$/;
const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const minDob = new Date(1900, 0, 1);
// in case of Doogie Howser
const maxYob = new Date().getFullYear() - 10;
const maxDob = new Date(maxYob, 0, 1);

export const pslfFormSchema = object({
  hasMadeQualifyingPayments: boolean().required('Required'),
  isForbearanceChecked: boolean().nullable().notRequired(),
  email: string().nullable().matches(emailRegExp, 'Invalid email address').required('Required'),
  phoneNumber: string().nullable().phone().required('Required'),
  addressLine1: string().nullable().required('Required'),
  addressLine2: string().nullable().notRequired(),
  city: string().nullable().required('Required'),
  state: string().nullable().required('Required'),
  postalCode: string().nullable().required('Required').length(5, 'Invalid ZIP code'),
  dateOfBirth: date()
    .nullable()
    .required('Required')
    .min(minDob, `DOB must be after ${minDob.toLocaleDateString()}`)
    .max(maxDob, `DOB must be before ${maxDob.toLocaleDateString()}`),
  ssn: string().nullable().matches(ssnRegExp, 'Invalid SSN').required('Required'),
  borrowerFormType: string().oneOf(['priorEmployer', 'currentEmployer']).required(),
  selectedGroup: lazy((_, schema) => {
    if (schema.context.groupSelectRequired) {
      return object().required('Please select an employer');
    } else {
      return object().nullable().notRequired();
    }
  }),
  priorEmployerClosed: boolean().when('borrowerFormType', {
    is: 'priorEmployer',
    then: (pslfFormSchema) => pslfFormSchema.required('Required'),
  }),
  employerEin: string()
    .nullable()
    .when('priorEmployerClosed', {
      is: true,
      then: () => string().matches(feinRegExp, 'EIN must be 9 digits').required('Required'),
    }),
  employmentHours: number().when('priorEmployerClosed', {
    is: true,
    then: () => number().positive('Enter a number greater than 0').required('Required'),
  }),
  alternatePhone: string().nullable().notRequired(),
  employerContactName: string().nullable().notRequired(),
  employerContactPhone: lazy((value, schema) => {
    if (!schema.context.isPriorEmployer || value?.length === 0) {
      return string().nullable().notRequired();
    } else {
      return string().nullable().phone().notRequired();
    }
  }),
  employerContactTitle: string().nullable().notRequired(),
  noEmployerEmail: lazy((_, schema) => {
    if (schema.context.isNonPartner || schema.context.isPriorEmployer) {
      return boolean().required('Required');
    } else {
      return boolean().nullable().notRequired();
    }
  }),
  employerEmail: lazy((value, schema) => {
    if (schema.context.noEmployerEmail || value?.length === 0) {
      return string().nullable().notRequired();
    } else {
      return string().matches(emailRegExp, 'Invalid email address').required('Required');
    }
  }),
  emailCheck: lazy((_, schema) => {
    if (schema.context.noEmployerEmail || schema.context.isCurrentPartner) {
      return string().nullable().notRequired();
    } else {
      return string()
        .nullable()
        .test('emails-match', 'Emails must match', function (value) {
          return value === this.parent.employerEmail;
        });
    }
  }),
  employerName: lazy((_, schema) => {
    if (schema.context.isNonPartner || schema.context.isPriorEmployer) {
      return string().required('Required');
    } else {
      return string().nullable().notRequired();
    }
  }),
  employerWebsite: string().nullable().notRequired(),
  employmentStartDate: string().nullable().required('Required'),
  employmentEndDate: lazy((_, schema) => {
    if (schema.context.isPriorEmployer) {
      return string().required('Required');
    } else {
      return string().nullable().notRequired();
    }
  }),
});

export default null; // silence a false warning
