import Service, { service } from '@ember/service';
import type CableService from './cable.ts';

export type IndexProgress = {
  batchId: string;
  completed: number;
  failures: number;
  pending: number;
  progress: number;
  total: number;
  type: string;
  status: 'queued' | 'running' | 'finished' | 'failed';
};

export default class RebuildIndexService extends Service {
  @service declare cable: CableService;
  // TODO: Figure out how to type this
  subscription: ReturnType<typeof this.cable.consumer>['subscriptions']['create'] | null = null;

  // TODO: move connection logic to cable service and use environment variables
  async connect(apiHost: string, apiKey: string, token: string) {
    this.cable.connect(apiHost, apiKey, token);
  }

  async subscribe(model: string, onUpdate: (msg: IndexProgress) => void) {
    this.subscription = this.cable.consumer.subscriptions.create(
      {
        channel: 'ReindexProgressChannel',
        model,
      },
      {
        received: (msg: IndexProgress) => {
          onUpdate(msg);
        },
      }
    );
  }

  async unsubscribe() {
    this.subscription.unsubscribe({
      channel: 'ReindexProgressChannel',
    });
    this.subscription = null;
  }

  // async broadcast(msg: Record<string, unknown>) {
  //   this.subscription?.perform('appear', { test: 321 });
  // }
}
