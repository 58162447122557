import type Owner from '@ember/owner';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

// This is just a simple service so we can globally track the dark mode state
// across the app. Ideally we would just allow the browser to handle this, but
// until it is ready for release this is useful.
export default class DarkModeService extends Service {
  @tracked enabled = false;

  constructor(owner?: Owner) {
    super(owner);

    this.enabled = localStorage.getItem('dark-mode') === 'true';
  }
}
