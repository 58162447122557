import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type EmployeeModel from './employee.ts';
import type UserModel from './user.ts';

export default class StrategyFinderResultModel extends Model {
  @attr declare name: string;
  @attr declare updatedAt: string;
  @attr declare chosenPlan: Record<string, unknown>;
  @attr declare currentPlan: Record<string, unknown>;
  @attr declare recommendedPlan: Record<string, unknown>;

  /*************************
   **  Relationships      **
   *************************/

  @hasMany('employee', { async: false, inverse: 'strategyFinderResult' })
  declare employees: EmployeeModel[];

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;

  /**************************
   **  Computed Properties **
   **************************/

  get isComplete() {
    return !!(this.chosenPlan && this.currentPlan && this.recommendedPlan);
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'strategy-finder-result': StrategyFinderResultModel;
  }
}
