import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import type FeaturesService from './features.ts';

/**
 * A service for retrieving assets such as images and bulk text.
 *
 * @memberof services
 */
class StaticAssetsService extends Service {
  @service declare features: FeaturesService;

  /**
   * The subdomain used to access this application, or an empty string if no
   * subdomain exists.
   */
  get subdomain() {
    return window.location.hostname.split('.').slice(0, -2).join('.');
  }

  /**
   * Returns the static JSON with the `name`.
   */
  @action
  async getStaticJson(name: string) {
    const response = await this.findAsset([
      this.subdomain && `staticData/${name}_${this.subdomain}.json`,
      `staticData/${name}.json`,
    ]);

    return await response?.json();
  }

  /**
   * Returns the first asset found at one of the `paths`, returning the
   * response if the asset exists and is accessible, or `undefined` otherwise.
   */
  @action
  async findAsset(paths: string[]) {
    let response;

    for (const path of paths) {
      if (path) {
        response = await this.fetchAsset(path);
      }

      if (response) {
        break;
      }
    }

    return response;
  }

  /**
   * Fetches an asset at the `path`, returning the response if the asset
   * exists and is accessible, or `undefined` otherwise.
   */
  @action
  async fetchAsset(path: string) {
    try {
      const response = await fetch(this.getAssetUrl(path));

      // TODO: Maybe distinguish between 403/404 and other errors like 500 that
      //       might indicate the document is available, but not presently
      //       accessible [twl 6.Oct.22]
      return response.ok ? response : undefined;
    } catch (e) {
      // TODO: Potentially log this to an error service. This occurs for CORS
      //       and network errors, but not for HTTP errors like 403/404, so
      //       indicates a deeper issue than the document missing [twl 6.Oct.22]
      console.error(e);

      return undefined;
    }
  }

  /**
   * Returns the full URL used to access an asset at the `path`.
   */
  getAssetUrl(path: string) {
    return `https://tuitionio-${this.features.tioEnvironment}-images.s3.amazonaws.com/${path}`;
  }
}

export default StaticAssetsService;
