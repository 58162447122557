import Service, { service } from '@ember/service';
import { action } from '@ember/object';
// @ts-expect-error: TODO: No types available for this package
import type IntlService from '@ember-intl/services/intl';

export default class StrategyFinderService extends Service {
  @service declare intl: IntlService;

  repaymentPlans = {
    TEN_YEARS_STANDARD: 'tenYearsStandard',
    EXTENDED_PLAN: 'extendedPlan',
    PAYE: 'paye',
    REPAYE: 'repaye',
    GRADUATED: 'graduated',
    IBR: 'ibr',
    ICR: 'icr',
  };

  // These are options for any select input for a user
  // who needs to select their repayment plan.
  get repaymentPlanOptions() {
    return Object.values(this.repaymentPlans).map((planType) => {
      return {
        label: this.intl.t(`common.repayment_plans.${planType}`),
        value: planType,
      };
    });
  }

  @action
  formatCentsRange(centsRange: Array<number | string> = []) {
    const formatted = centsRange.map((cents) => {
      const dollars = +cents / 100;
      return this.intl.formatNumber(dollars, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }) as string;
    });
    return formatted.join(' - ');
  }

  @action
  formatMonthsRange(monthsRange = []) {
    const formatted = monthsRange.map((amount) => {
      if (amount > 12) {
        return Math.ceil(amount / 12);
      }
      return `${amount} ${this.intl.t('recommendations_estimator.months')}`;
    });
    return formatted.join(' - ');
  }

  @action
  formatYearsRange(monthsRange: number[] = []) {
    const formatted = monthsRange.map((amount) => {
      const currentYear = new Date().getFullYear();
      const approxYearsToRepay = Math.ceil(amount / 12);
      return currentYear + approxYearsToRepay;
    });
    return formatted.join(' - ');
  }

  @action
  formatDateRange(dateRange: string[] = []) {
    const formatted = dateRange.map((date) => {
      return this.intl.formatDate(date, {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      });
    });
    return formatted.join(' - ');
  }
}
