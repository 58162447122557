import type Store from '@ember-data/store';
import { service } from '@ember/service';
import Model, { attr, belongsTo } from '@ember-data/model';

import type EmployeeModel from './employee.ts';

export default class InviteModel extends Model {
  @service declare store: typeof Store;

  @attr declare isActive: boolean;
  @attr declare enrollmentLink: string;
  @attr declare fromDate: string;
  @attr declare expirationDate: string;

  @belongsTo('employee', { async: false, inverse: null })
  declare user: EmployeeModel;

  get isExpired() {
    return new Date(this.expirationDate) < new Date();
  }
}
