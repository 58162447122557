import Model, { hasMany } from '@ember-data/model';
import type TagModel from './tag.ts';

export default class Taggable extends Model {
  /*************************
   **  Relationships      **
   *************************/
  @hasMany('tag', {
    async: false,
    inverse: 'taggable',
    as: 'taggable',
  })
  declare tags: TagModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    taggable: Taggable;
  }
}
