import { helper } from '@ember/component/helper';
import type ProgramTemplate from '..//models/tas-program-template.ts';
import type { TemplateFieldName } from '../utils/tuition-assistance/fields.ts';
/**
  {{is-program-required "SCHOOL_INSTITUTION_NAME" programTemplate=@tasProgramTemplate}}
 */
export default helper(function isProgramRequired(
  [field]: [TemplateFieldName],
  { programTemplate }: { programTemplate: ProgramTemplate }
) {
  if (!field || !programTemplate) {
    return false;
  }

  return !!programTemplate.fields?.[field]?.required;
});
