import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

type Positional = [string | undefined];
type Named = {
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
};

export interface SafeFormatTimeSignature {
  Args: {
    Positional: Positional;
    Named: Named;
  };
  Return: string | null;
}

/**
 * Returns a formatted time if a parsable value exists, otherwise return null
 * without raising an app-blocking error.
 *
 * {{safe-format-time @createdAt}}
 *
 */
export default class SafeFormatTime extends Helper<SafeFormatTimeSignature> {
  @service declare intl: IntlService;

  public compute([positional]: Positional, named: Named): string | null {
    if (!positional) {
      return null;
    }

    try {
      return this.intl.formatTime(positional, named);
    } catch (e) {
      console.warn(e);
    }
    return null;
  }
}
