import Model, { hasMany } from '@ember-data/model';
import type MessageModel from './message.ts';

export default class MessageableModel extends Model {
  /*************************
   **  Relationships      **
   *************************/

  @hasMany('message', {
    async: false,
    inverse: 'messageable',
    as: 'messageable',
  })
  declare messages: MessageModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    messageable: MessageableModel;
  }
}
