import type { StringSchema, NumberSchema } from 'yup';
import { object, string, number } from 'yup';

const newManualTransaction = object({
  transactionAmount: (number() as NumberSchema)
    .moreThan(0, 'amount must be more than $0')
    .required(),
  transactionDate: (string() as StringSchema).required('enter a valid transaction date'),
  transactionNotes: (string() as StringSchema).required('enter zendesk link or notes'),
});

export default newManualTransaction;
