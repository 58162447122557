import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service } from '@ember/service';
import type Intl from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type PslfProfileModel from './pslf-profile.ts';
import type AttachmentModel from './attachment.ts';
import type PslfFormVersionModel from './pslf-form-version.ts';

export type BorrowerFields = {
  'borrower-address': string;
  'borrower-alternate-phone': string;
  'borrower-apartment': string;
  'borrower-birth-date': string;
  'borrower-city': string;
  'borrower-email': string;
  'borrower-employer-contact-name': string;
  'borrower-employer-contact-phone': string;
  'borrower-employer-contact-title': string;
  'borrower-employer-ein': string;
  'borrower-employer-email': string;
  'borrower-employer-name': string;
  'borrower-employer-website': string;
  'borrower-employment-continues': boolean;
  'borrower-employment-end-date': string;
  'borrower-employment-hours': string;
  'borrower-employment-start-date': string;
  'borrower-employment-status': string;
  'borrower-info-changed': boolean;
  'borrower-invite-id': string;
  'borrower-is-multi-employer-full-time': boolean;
  'borrower-name': string;
  'borrower-non-partner-form': boolean;
  'borrower-primary-phone': string;
  'borrower-print-and-mail': boolean;
  'borrower-prior-employer-closed': boolean;
  'borrower-reason': string;
  'borrower-sign-date': string;
  'borrower-state': string;
  'borrower-wants-forbearance': boolean;
  'borrower-zip': string;
  'embed-url': string;
};

export type EmployerFields = {
  'employer-apartment': string;
  'employer-city': string;
  'employer-contact-email': string;
  'employer-contact-name': string;
  'employer-contact-phone': string;
  'employer-contact-title': string;
  'employer-email': string;
  'employer-fein': string;
  'employer-group-id': string;
  'employer-invite-id': string;
  'employer-is-government': boolean;
  'employer-is-not-for-profit': boolean;
  'employer-is-political': boolean;
  'employer-is-tax-exempt': boolean;
  'employer-name': string;
  'employer-services': string[];
  'employer-sign-date': string;
  'employer-state': string;
  'employer-street-address': string;
  'employer-website': string;
  'employer-zip': string;
  'employment-continues': boolean;
  'employment-end-date': string;
  'employment-hours': string;
  'employment-start-date': string;
  'employment-status': string;
};

export default class PslfFormModel extends Model {
  @service declare intl: Intl;
  @service declare store: typeof Store;

  @attr declare status: string;
  @attr declare documentId: string;
  @attr declare payrollId: string;
  @attr declare faxId: string;
  @attr declare borrowerFields: BorrowerFields;
  @attr declare employerFields: EmployerFields;
  @attr declare borrowerCompletedAt: string;
  @attr declare employerCompletedAt: string;
  @attr declare submittedAt: string;
  @attr declare formType: string;
  @attr declare isNonPartner: boolean;
  @attr declare isPreApproved: boolean;
  @attr declare employerSignLink: string;
  @attr declare faxVerified: number;

  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('pslf-profile', { async: false, inverse: 'pslfForms' })
  declare pslfProfile: PslfProfileModel;
  @hasMany('attachment', { async: false, inverse: null })
  declare attachments: AttachmentModel[];
  @hasMany('pslf-form-version', { async: false, inverse: null })
  declare versions: PslfFormVersionModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get isPriorEmployer() {
    return this.formType === 'priorEmployer';
  }

  get isCurrentEmployer() {
    return this.formType === 'currentEmployer';
  }

  get isNonPartnerApproverForm() {
    return this.isNonPartner;
  }

  get isPriorEmployerClosed() {
    return this.borrowerFields['borrower-prior-employer-closed'];
  }

  get isPrintAndMail() {
    return !!this.borrowerFields['borrower-print-and-mail'];
  }

  get priorEmployerName() {
    return this.borrowerFields['borrower-employer-name'] || '';
  }

  get employerDisplayName() {
    return (
      this.borrowerFields['borrower-employer-name'] || this.employerFields?.['employer-name'] || ''
    );
  }

  get fullEmployerAddress() {
    const line1 = this.employerFields?.['employer-street-address'] || '';
    const line2 = this.employerFields?.['employer-apartment'] || '';
    const city = this.employerFields?.['employer-city'] || '';
    const state = this.employerFields?.['employer-state'] || '';
    const zip = this.employerFields?.['employer-zip'] || '';
    return `${line1} ${line2} ${city}, ${state} ${zip}`;
  }

  get approverContactName() {
    return this.employerFields?.['employer-contact-name'];
  }

  get approverContactPhone() {
    return this.employerFields?.['employer-contact-phone'];
  }

  // TODO: Type employer fields so inference will work.
  get approverDisplayEmail(): string {
    if (this.isPrintAndMail) {
      return `${this.intl.t('pslf.table.not_applicable')} - ${this.intl.t(
        'pslf.table.print_and_mail'
      )}`;
    }
    if (this.isPriorEmployerClosed) {
      return `${this.intl.t('pslf.table.not_applicable')} - ${this.intl.t(
        'pslf.table.prior_employer_closed'
      )}`;
    }
    if (this.isPriorEmployer || this.isNonPartner) {
      return this.borrowerFields?.['borrower-employer-email'] || '';
    }
    return (
      this.employerFields?.['employer-email'] ||
      this.employerFields?.['employer-contact-email'] ||
      ''
    );
  }

  get isAwaitingSignature() {
    return ['created', 'borrower_signed'].includes(this.status);
  }

  get isEmployerSignLinkValid() {
    if (this.isPriorEmployerClosed || this.isPrintAndMail) {
      return false;
    }
    return this.isAwaitingSignature;
  }

  get hasPdfPreview() {
    return this.attachments.length > 0;
  }

  get canTransferPdf() {
    return !!this.documentId && this.status !== 'created';
  }

  get canFax() {
    return [
      'awaiting_submit',
      'submit_errored',
      'submitted',
      're_submitted',
      're_submit_errored',
    ].includes(this.status);
  }

  get canCancel() {
    return ['borrower_signed', 'employer_signed', 'awaiting_submit', 'submit_errored'].includes(
      this.status
    );
  }

  get canChangeApprover() {
    if (this.isPriorEmployerClosed || this.isPrintAndMail) {
      return false;
    }

    return (
      ['borrower_signed'].includes(this.status) &&
      (this.isPriorEmployer || this.isNonPartnerApproverForm)
    );
  }

  get canResendEmail() {
    return (
      ['borrower_signed'].includes(this.status) &&
      !this.isPrintAndMail &&
      !this.isPriorEmployerClosed
    );
  }

  /**************************
   **  Methods **
   **************************/

  async sendFax() {
    return this.store.adapterFor('pslf-form').sendFax(this);
  }

  async transferPdfFromSignNow() {
    return this.store.adapterFor('pslf-form').transferPdfFromSignNow(this);
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'pslf-form': PslfFormModel;
  }
}
