import { object, string, boolean, lazy, number } from 'yup';

const feinRegExp = /^\d{2}-?\d{7}$/;

export const currentEmployerApproval = object({
  employmentStartDate: string().required('Required'),
  employmentEndDate: string().when('currentEmployee', {
    is: false,
    then: (currentEmployerApproval) => currentEmployerApproval.required('Required'),
  }),
  currentEmployee: boolean().required('Required'),
  weeklyHours: number().min(0, 'Must be a positive number').required('Required'),
  employmentStatus: string().required('Required'),
  employerInfoCorrect: boolean().required('Required'),
});

export const currentNonPartnerEmployerApproval = object({
  employerApartment: string().nullable().notRequired(),
  employerCity: string().nullable().required('City is required'),
  employerEin: string().nullable().matches(feinRegExp, 'EIN must be 9 digits').required('Required'),
  employerName: string().nullable().required('Company name is required'),
  employerState: string().nullable().required('Company state is required'),
  employerStreetAddress: string().nullable().required('Street address is required'),
  employerWebsite: string().nullable().notRequired(),
  employerZip: string().nullable().required('Postal code is required'),
  employmentEndDate: lazy((_, schema) => {
    if (schema.context.currentEmployee) {
      return string().nullable().notRequired();
    } else {
      return string().required('End date is required if employee is not current');
    }
  }),
  employmentHours: number().nullable().min(0, 'Must be a positive number').required('Required'),
  employmentStartDate: string().nullable().required('Required'),
  employmentStatus: string().nullable().required('Required'),
});

export default null; // silence a false warning
