import Helper from '@ember/component/helper';

type Positional = [string];

export interface MatchMediaSignature {
  Args: {
    Positional: Positional;
  };
  Return: boolean;
}

/**
 * Returns true if the current screen size matches the passed media query string
 * Example usage:
 *
 *   {{#if (match-media "(min-width: 900px) and (max-width: 1200px)")}}
 *     <p>You content here</p>
 *   {{/if}}
 *
 * @param {array} positional - The positional parameters for this function
 * @param {string} positional[0] - A media query string to match (ex. '(min-width: 300px) and (max-width: 600px)' or '(min-width: 1200px)')
 *
 * @return {boolean} Returns a boolean representing if a MediaQueryList object matches a passed media query string
 */
export default class MatchMedia extends Helper<MatchMediaSignature> {
  mediaQueryList: MediaQueryList | null = null;
  onChangeHandler: ((event: MediaQueryListEvent) => void) | null = null;

  compute([mediaQueryString]: Positional) {
    return this.addMatchMediaListener(mediaQueryString);
  }

  willDestroy() {
    this.removeMatchMediaListener();
  }

  addMatchMediaListener(mediaQueryString: string) {
    this.removeMatchMediaListener();

    this.onChangeHandler = (event: MediaQueryListEvent) => {
      // @ts-expect-error: event is not assignable to type MediaQueryListEvent
      this.recompute(event.matches);
    };

    this.mediaQueryList = window.matchMedia(mediaQueryString);

    this.mediaQueryList.addEventListener('change', this.onChangeHandler);

    return this.mediaQueryList.matches;
  }

  removeMatchMediaListener() {
    if (this.onChangeHandler) {
      this.mediaQueryList?.removeEventListener('change', this.onChangeHandler);
      this.mediaQueryList = null;
      this.onChangeHandler = null;
    }
  }
}
