import Model, { attr, belongsTo } from '@ember-data/model';
import type AggregationAccountModel from './aggregation-account.ts';
import type AggregationLoanModel from './aggregation-loan.ts';

export default class AggregationTransactionModel extends Model {
  @attr declare amount: number;
  @attr declare description: string;
  @attr declare feeAmount: number;
  @attr declare interestAmount: number;
  @attr declare postedDate: string;
  @attr declare principalAmount: number;
  @attr declare provider: string;
  @attr declare transactionDate: string;

  @attr declare createdAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('aggregation-account', { async: false, inverse: null })
  declare aggregationAccount: AggregationAccountModel;

  @belongsTo('aggregation-loan', {
    async: false,
    inverse: 'aggregationTransaction',
  })
  declare aggregationLoan: AggregationLoanModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'aggregation-transaction': AggregationTransactionModel;
  }
}
