import Model, { hasMany } from '@ember-data/model';
import type CommentModel from './comment.ts';

export default class Commentable extends Model {
  /*************************
   **  Relationships      **
   *************************/
  @hasMany('comment', {
    async: false,
    inverse: 'commentable',
    as: 'commentable',
  })
  declare comments: CommentModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    commentable: Commentable;
  }
}
