import { object, string, number, date, array, ref } from 'yup';

export const courseDurationSchema = object({
  COURSES_BEGIN_DATE: date(),
  COURSES_END_DATE: date().min(
    ref('COURSES_BEGIN_DATE'),
    'Course End Date must be after begin date'
  ),
  SCHOLARSHIPS_RECEIVED: array().of(
    object().shape({
      scholarshipName: string(),
      scholarshipAmount: number()
        .moreThan(0, 'Amount must be greater than $0')
        .required('Amount is required'),
    })
  ),
  COURSES_RECEIPT_DATE: date(),
});

export default null; // silence a false warning
