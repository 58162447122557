import { assert } from '@ember/debug';

/**
 * Calculates the original value before a percentage was applied based on the given post-percentage
 * value and the percentage.
 *
 * @param {number} postPercentageValue The value after the percentage has been applied. This is the
 *                                     final amount after increasing or decreasing the original value
 *                                     by a certain percentage.
 * @param {number} percentage          The percentage that was applied to the original value, represented
 *                                     as a whole number (e.g., 70 for 70%). This is not the decimal
 *                                     representation (0.7 for 70%).
 *
 * @return {number} The calculated original value (pre-percentage value) before any percentage was applied.
 *                  This is determined by dividing the post-percentage value by the decimal form of the
 *                  percentage.
 *
 * @example
 * // Returns 1000 when the post-percentage value is 700 and the percentage applied was 70%
 * calculatePrePercentageValue(700, 70);
 */
export default function calculatePrePercentageValue(
  postPercentageValue: number,
  percentage: number
): number {
  if (!Number.isInteger(percentage) || percentage < 0 || percentage > 100) {
    assert('Percentage must be a whole number between 0 and 100.');
  }
  // Convert percentage to a decimal for calculation
  const decimalPercentage = percentage / 100;

  // Calculate the original (pre-percentage) value
  const prePercentageValue = postPercentageValue / decimalPercentage;

  return prePercentageValue;
}
