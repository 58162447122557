import type TasGroupModel from '../../models/tas-group.ts';
import type TasProgramInstanceModel from '../../models/tas-program-instance.ts';

export function hasInProgressInstanceOfCurrentProgramTemplate(
  tasGroups: TasGroupModel[],
  programTemplateId: string,
  activeInstances: TasProgramInstanceModel[]
) {
  const indexesToCheck = indexOfCurrentProgramIdFoundInTasGroupings(tasGroups, programTemplateId);
  if (!indexesToCheck || indexesToCheck.length === 0) {
    return false;
  }

  let hasInProgressProgram = false;

  indexesToCheck.forEach((indexToCheck) => {
    // This is the array of tasGroupings that we need to check for in-progress programs
    const exclusiveProgramsToCheck = tasGroups?.[indexToCheck]?.tasGroupings
      ?.filter((memberGrouping) => memberGrouping.tasProgramTemplate.id !== programTemplateId)
      .map((memberGrouping) => memberGrouping.tasProgramTemplate.id);

    if (exclusiveProgramsToCheck) {
      // Filter active instances that belong to exclusive programs
      const filteredProgramInstanceGroups = activeInstances.filter((instance) => {
        const templateId = instance.tasProgramTemplate.id;
        return exclusiveProgramsToCheck.includes(templateId);
      });

      // Check if any of the filtered instances are in-progress
      hasInProgressProgram =
        hasInProgressProgram ||
        filteredProgramInstanceGroups.some((instance) => instance.programInstanceInProgress);
    }
  });

  return hasInProgressProgram;
}

export function indexOfCurrentProgramIdFoundInTasGroupings(
  tasGroups: TasGroupModel[],
  programTemplateId: string
) {
  const templateIdGroups = tasGroups.map((group) =>
    group.tasGroupings.map((grouping) => grouping.tasProgramTemplate.id)
  );
  return findTasGroupingWithTemplateId(templateIdGroups, programTemplateId);
}

export function findTasGroupingWithTemplateId(arr: string[][], programTemplateId: string) {
  const indices: number[] = [];
  arr.findIndex((innerArr, index) => {
    if (innerArr.includes(programTemplateId)) {
      indices.push(index);
    }
  });
  return indices;
}

export default null; // silence a false warning
