import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type EmployeeModel from './employee.ts';
import type AttachmentModel from './attachment.ts';
import type PslfFormModel from './pslf-form.ts';
import type PslfAssetModel from './pslf-asset.ts';

export default class PslfProfileModel extends Model {
  @attr declare isInterested: boolean;
  @attr declare socialSecurity: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('employee', { async: false, inverse: 'pslfProfile' })
  declare employee: EmployeeModel;
  @hasMany('attachment', { async: false, inverse: null })
  declare attachments: AttachmentModel[];
  @hasMany('pslf-form', { async: false, inverse: 'pslfProfile' })
  declare pslfForms: PslfFormModel[];
  @hasMany('pslf-asset', { async: false, inverse: 'pslfProfiles' })
  declare pslfAssets: PslfAssetModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'pslf-profile': PslfProfileModel;
  }
}
