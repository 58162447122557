import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
// @ts-expect-error: TODO: No types available for this package
import type IntlService from '@ember-intl/services/intl';
import type AccountModel from './account.ts';

interface LoanObservationSignature {
  'balance-date': string;
  'interest-balance': number;
  'interest-rate': number;
  'loan-class': string;
  'next-payment-amount': number;
  'next-payment-date': string;
  'original-amount': number;
  'original-date': string;
  'parent-plus': string;
  'principal-balance': number;
  'repayment-plan': string;
  at: string;
  balance: number;
  name: string;
  status: string;
}

export default class LoanModel extends Model {
  @service declare intl: IntlService;

  @attr declare number: number;
  @attr declare deactivatedOn: string;
  @attr declare observation: LoanObservationSignature;
  @attr declare observationOverrides: Record<string, unknown>[];
  @attr declare isActive: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('account', { async: false, inverse: 'loans' })
  declare account: AccountModel;

  /**************************
   **  Computed Properties **
   **************************/

  get currentBalance() {
    return this.observation?.balance || 0;
  }

  get currentBalanceDollars() {
    return this.observation?.balance / 100 || 0;
  }

  get interestRate() {
    return this.observation?.['interest-rate'];
  }

  get interestBalance() {
    return this.observation?.['interest-balance'] || 0;
  }

  get principalBalance() {
    return this.observation?.['principal-balance'] || 0;
  }

  get originalAmount() {
    return this.observation?.['original-amount'];
  }

  get originalDate() {
    return this.observation?.['original-date'];
  }

  get nextPaymentAmount() {
    return this.observation?.['next-payment-amount'];
  }

  get nextPaymentDate() {
    return this.observation?.['next-payment-date'];
  }

  get lastUpdatedOn() {
    return this.observation?.at;
  }

  get repaymentPlan() {
    return this.observation?.['repayment-plan'];
  }

  get status() {
    return this.observation?.status;
  }

  get loanClass() {
    return this.observation?.['loan-class'];
  }

  get name() {
    return this.observation?.name;
  }

  get currentBalanceFormatted() {
    const dollars = this.currentBalance;
    return this.intl.formatNumber(dollars, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get loanTypeDisplayName() {
    const loanClass = this.observation?.['loan-class'];
    const isParentPlus = this.observation?.['parent-plus'] === 'YES';
    if (loanClass === 'DIRECT' && isParentPlus) {
      return 'Parent PLUS'; // todo - translations
    }
    return loanClass;
  }

  get isValidForProjections() {
    const { observation, interestRate, principalBalance, nextPaymentAmount, nextPaymentDate } =
      this;
    if (!observation) {
      return false;
    }

    return principalBalance && interestRate && nextPaymentAmount && nextPaymentDate;
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    loan: LoanModel;
  }
}
