export function monthsAgo(months: number) {
  const today = new Date();
  const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - months, 1);

  // Format the date as 'YYYY-MM-DD'
  const year = threeMonthsAgo.getFullYear();
  const month = (threeMonthsAgo.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to month because months are zero-based
  const day = '01'; // Start of the month

  return `${year}-${month}-${day}`;
}

export function daysAgo(days: number) {
  const today = new Date();
  const sevenDaysAgo = new Date(today.getTime() - days * 24 * 60 * 60 * 1000);

  // Format as YYYY-mm-dd
  return (
    sevenDaysAgo.getFullYear() +
    '-' +
    String(sevenDaysAgo.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(sevenDaysAgo.getDate()).padStart(2, '0')
  );
}

export default null; // silence a false warning
