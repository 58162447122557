import Model, { attr, belongsTo } from '@ember-data/model';
import type CompanyModel from './company.ts';
import type EmailTemplateModel from 'tio-common/models/email-template';

export default class CustomCompanyEmailTemplateModel extends Model {
  @attr declare name: string;
  @attr declare subject: string;
  @attr declare editorJsData: Record<string, unknown>[];
  @attr declare isActive: boolean;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('company', { async: false, inverse: 'customCompanyEmailTemplates' })
  declare company: CompanyModel;
  @belongsTo('email-template', { async: false, inverse: 'customCompanyEmailTemplates' })
  declare emailTemplate: EmailTemplateModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'custom-company-email-template': CustomCompanyEmailTemplateModel;
  }
}
