import Model, { attr, hasMany } from '@ember-data/model';
import type EmployeeModel from './employee.ts';
import type UserModel from './user.ts';
import type AgentModel from './agent.ts';

export type CustomAttributes = {
  'agent-id'?: string;
  'agent-name'?: string;
  'agent-phone'?: string;
  'agent-email'?: string;
  'agent-website'?: string;
  'agency-name'?: string;
  'agent-address-line1'?: string;
  'agent-address-line2'?: string;
  'agent-address-city'?: string;
  'agent-address-state'?: string;
  'agent-address-zip'?: string;
};

export default class WorkspaceModel extends Model {
  @attr declare name: string;
  @attr declare description: string;
  @attr declare customAttributes: CustomAttributes;

  /*************************
   **  Relationships      **
   *************************/

  @hasMany('employee', { async: false, inverse: 'workspace' })
  declare employees: EmployeeModel[];
  @hasMany('user', { async: false, inverse: null })
  declare users: UserModel[];
  @hasMany('agent', { async: false, inverse: null })
  declare agents: AgentModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    workspace: WorkspaceModel;
  }
}
