// app/helpers/json-pretty-print.js
// usage {{json-pretty-print someJson}}
// see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#The_space_argument

type JSONValue =
  | string
  | number
  | boolean
  | null
  | { [key: string]: JSONValue }
  | JSONValue[]
  | unknown;
type Options = {
  level?: number;
  isOpen?: boolean;
};
function renderJson(value: JSONValue, options?: Options): string {
  const level = options?.level || 0;
  const isOpen = options?.isOpen ?? true;

  if (typeof value === 'string') {
    return `<span class="text-green-500">"${value}"</span>`;
  }
  if (typeof value === 'number') {
    return `<span class="text-orange-500">${value}</span>`;
  }
  if (typeof value === 'boolean') {
    return `<span class="text-blue-500">${value}</span>`;
  }
  if (value === null) {
    return `<span class="text-purple-500">null</span>`;
  }
  if (Array.isArray(value)) {
    return `
        <details class="ml-${level * 4}" ${isOpen ? 'open' : ''}>
          <summary class="cursor-pointer text-gray-800 hover:text-gray-600">[Array(${value.length})]</summary>
          <div class="ml-4 border-l border-gray-300 pl-4">
            ${value.map((item) => renderJson(item, { level: level + 1, isOpen })).join('')}
          </div>
        </details>
      `;
  }
  if (typeof value === 'object') {
    return `
        <details class="ml-${level * 4}" ${isOpen ? 'open' : ''}>
          <summary class="cursor-pointer text-gray-800 hover:text-gray-600">{Object}</summary>
          <div class="ml-4 border-l border-gray-300 pl-4">
            ${Object.entries(value)
              .map(
                ([key, val]) =>
                  `<div><span class="text-red-500">"${key}":</span> ${renderJson(val, {
                    level: level + 1,
                    isOpen,
                  })}</div>`
              )
              .join('')}
          </div>
        </details>
      `;
  }
  return '';
}

export default function jsonPrettyPrint(json: JSONValue, options?: Options): string {
  return `<div class="break-keep font-mono text-sm bg-gray-100 p-4 rounded-lg">${renderJson(
    json,
    options
  )}</div>`;
}

interface JsonObject {
  [key: string]: JsonValue;
}

type JsonValue = string | number | boolean | null | JsonObject | JsonValue[];

interface Changes {
  [key: string]: [JsonValue, JsonValue];
}

export function compareJsonObjects<T extends JsonObject>(
  obj1: T,
  obj2: T,
  prefix: string = ''
): Changes {
  const changes: Changes = {};

  function addChange(key: string, value1: JsonValue, value2: JsonValue) {
    const fullKey = prefix ? `${prefix}.${key}` : key;
    changes[fullKey] = [value1, value2];
  }

  function compareArrays(arr1: JsonValue[], arr2: JsonValue[], key: string) {
    if (arr1.length !== arr2.length) {
      addChange(key, arr1, arr2);
      return;
    }
    for (let i = 0; i < arr1.length; i++) {
      // @ts-expect-error: This is a runtime check
      if (isObject(arr1[i]) && isObject(arr2[i])) {
        const nestedChanges = compareJsonObjects(
          arr1[i] as JsonObject,
          arr2[i] as JsonObject,
          `${key}[${i}]`
        );
        Object.assign(changes, nestedChanges);
      } else if (arr1[i] !== arr2[i]) {
        // @ts-expect-error: This is a runtime check
        addChange(`${key}[${i}]`, arr1[i], arr2[i]);
      }
    }
  }

  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      if (Object.prototype.hasOwnProperty.call(obj2, key)) {
        // @ts-expect-error: This is a runtime check
        if (isObject(obj1[key]) && isObject(obj2[key])) {
          const nestedChanges = compareJsonObjects(
            obj1[key] as JsonObject,
            obj2[key] as JsonObject,
            prefix ? `${prefix}.${key}` : key
          );
          Object.assign(changes, nestedChanges);
        } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          compareArrays(
            obj1[key] as JsonValue[],
            obj2[key] as JsonValue[],
            prefix ? `${prefix}.${key}` : key
          );
        } else if (obj1[key] !== obj2[key]) {
          // @ts-expect-error: This is a runtime check
          addChange(key, obj1[key], obj2[key]);
        }
      } else {
        // @ts-expect-error: This is a runtime check
        addChange(key, obj1[key], null);
      }
    }
  }

  for (const key in obj2) {
    if (
      Object.prototype.hasOwnProperty.call(obj2, key) &&
      !Object.prototype.hasOwnProperty.call(obj1, key)
    ) {
      // @ts-expect-error: This is a runtime check
      addChange(key, null, obj2[key]);
    }
  }

  return changes;
}

export function isObject(value: JsonValue): value is JsonObject {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}
