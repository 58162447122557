import Model, { attr, belongsTo } from '@ember-data/model';
import type LegacyAuthorizationResourceEndpointModel from './legacy-authorization-resource-endpoint.ts';

export default class LegacyAuthorizationResourceUserRelationshipTypeModel extends Model {
  @attr declare userRelationshipTypeId: string;
  @attr declare resourceId: string;
  @attr declare updatedAt: string;

  @belongsTo('legacy-authorization-resource-endpoint', {
    async: false,
    inverse: 'legacyAuthorizationResourceUserRelationshipType',
  })
  declare legacyAuthorizationResourceEndpoint: LegacyAuthorizationResourceEndpointModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'legacy-authorization-resource-user-relationship-type': LegacyAuthorizationResourceUserRelationshipTypeModel;
  }
}
