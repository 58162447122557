import Model, { attr, belongsTo } from '@ember-data/model';
import type InvoiceModel from './invoice.ts';
import type EmployeeModel from './employee.ts';

export default class InvoiceLineItemModel extends Model {
  @attr declare amount: number;
  @attr declare description: string;
  @attr declare lineItemType: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('invoice', { async: false, inverse: null })
  declare invoice: InvoiceModel;
  @belongsTo('employee', { async: false, inverse: null })
  declare employee: EmployeeModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'invoice-line-item': InvoiceLineItemModel;
  }
}
