import Model, { attr } from '@ember-data/model';

export default class ScholarshipModel extends Model {
  @attr declare amount: number;
  @attr declare fromDate: string;
  @attr declare isActive: boolean;
  @attr declare isDependent: boolean;
  @attr declare scholarshipAward: string;
  @attr declare scholarshipStatusType: string;
  @attr declare thruDate: string;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    scholarship: ScholarshipModel;
  }
}
