export const stateOptions = [
  {
    name: 'Alabama',
    abbr: 'AL',
  },
  {
    name: 'Alaska',
    abbr: 'AK',
  },
  {
    name: 'Arizona',
    abbr: 'AZ',
  },
  {
    name: 'Arkansas',
    abbr: 'AR',
  },
  {
    name: 'California',
    abbr: 'CA',
  },
  {
    name: 'Colorado',
    abbr: 'CO',
  },
  {
    name: 'Connecticut',
    abbr: 'CT',
  },
  {
    name: 'Delaware',
    abbr: 'DE',
  },
  {
    name: 'Florida',
    abbr: 'FL',
  },
  {
    name: 'Georgia',
    abbr: 'GA',
  },
  {
    name: 'Hawaii',
    abbr: 'HI',
  },
  {
    name: 'Idaho',
    abbr: 'ID',
  },
  {
    name: 'Illinois',
    abbr: 'IL',
  },
  {
    name: 'Indiana',
    abbr: 'IN',
  },
  {
    name: 'Iowa',
    abbr: 'IA',
  },
  {
    name: 'Kansas',
    abbr: 'KS',
  },
  {
    name: 'Kentucky',
    abbr: 'KY',
  },
  {
    name: 'Louisiana',
    abbr: 'LA',
  },
  {
    name: 'Maine',
    abbr: 'ME',
  },
  {
    name: 'Maryland',
    abbr: 'MD',
  },
  {
    name: 'Massachusetts',
    abbr: 'MA',
  },
  {
    name: 'Michigan',
    abbr: 'MI',
  },
  {
    name: 'Minnesota',
    abbr: 'MN',
  },
  {
    name: 'Mississippi',
    abbr: 'MS',
  },
  {
    name: 'Missouri',
    abbr: 'MO',
  },
  {
    name: 'Montana',
    abbr: 'MT',
  },
  {
    name: 'Nebraska',
    abbr: 'NE',
  },
  {
    name: 'Nevada',
    abbr: 'NV',
  },
  {
    name: 'New Hampshire',
    abbr: 'NH',
  },
  {
    name: 'New Jersey',
    abbr: 'NJ',
  },
  {
    name: 'New Mexico',
    abbr: 'NM',
  },
  {
    name: 'New York',
    abbr: 'NY',
  },
  {
    name: 'North Carolina',
    abbr: 'NC',
  },
  {
    name: 'North Dakota',
    abbr: 'ND',
  },
  {
    name: 'Ohio',
    abbr: 'OH',
  },
  {
    name: 'Oklahoma',
    abbr: 'OK',
  },
  {
    name: 'Oregon',
    abbr: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbr: 'PA',
  },
  {
    name: 'Rhode Island',
    abbr: 'RI',
  },
  {
    name: 'South Carolina',
    abbr: 'SC',
  },
  {
    name: 'South Dakota',
    abbr: 'SD',
  },
  {
    name: 'Tennessee',
    abbr: 'TN',
  },
  {
    name: 'Texas',
    abbr: 'TX',
  },
  {
    name: 'Utah',
    abbr: 'UT',
  },
  {
    name: 'Vermont',
    abbr: 'VT',
  },
  {
    name: 'Virginia',
    abbr: 'VA',
  },
  {
    name: 'Washington',
    abbr: 'WA',
  },
  {
    name: 'Washington, DC',
    abbr: 'DC',
  },
  {
    name: 'West Virginia',
    abbr: 'WV',
  },
  {
    name: 'Wisconsin',
    abbr: 'WI',
  },
  {
    name: 'Wyoming',
    abbr: 'WY',
  },
];

export const grossIncomes = [
  { amount: 'Less than 25K', send: 2500000 },
  { amount: '25K - 30K', send: 2750000 },
  { amount: '30K - 40K', send: 3500000 },
  { amount: '40K - 50K', send: 4500000 },
  { amount: '50K - 60K', send: 5500000 },
  { amount: '60K - 70K', send: 6500000 },
  { amount: '70K - 80K', send: 7500000 },
  { amount: '80K - 100K', send: 9000000 },
  { amount: '100K - 125K', send: 11250000 },
  { amount: '125K - 150K', send: 13750000 },
  { amount: '150K - 200K', send: 17500000 },
  { amount: '200K - 250K', send: 22500000 },
  { amount: '250K - 350K', send: 27500000 },
  { amount: '350K - 500K', send: 42500000 },
  { amount: 'More than 500K', send: 50000000 },
  { amount: 'Custom', send: null },
];

export const loanForgivenessOptions = [
  { label: 'Public Service Loan Forgiveness', value: 'PublicServiceLoanForgiveness' },
  { label: 'Teacher Loan Forgiveness', value: 'TeacherLoanForgiveness' },
  { label: 'Income-Driven Loan Forgiveness', value: 'IncomeDrivenLoanForgiveness' },
];

export default null;
