import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type EmployeeModel from './employee.ts';
import type TasEligibilityModel from './tas-eligibility.ts';
import type { TransitionLogSignature } from '../types/tuition-assistance.ts';

export default class TasParticipantModel extends Model {
  @attr declare payrollId: string;
  @attr declare state: string;
  @attr declare serviceData: Record<string, unknown>;
  @attr declare employmentStartDate: string;
  @attr declare transitionLog: TransitionLogSignature[];

  /*************************
   **  Relationships      **
   *************************/
  @hasMany('tas-eligibility', { async: false, inverse: 'tasParticipant' })
  declare tasEligibilities: TasEligibilityModel[];

  @belongsTo('employee', { async: false, inverse: 'tasParticipant' })
  declare employee: EmployeeModel;
  @belongsTo('employee', { async: false, inverse: null })
  declare supervisor: EmployeeModel;
  @belongsTo('employee', { async: false, inverse: null })
  declare departmentHead: EmployeeModel;

  // When a participant is "held" (aka "frozen" in product speak),
  // they are effectively barred from submitting any applications/programs.
  get isHeld() {
    return this.state === 'TAS.ParticipantState.HELD';
  }

  get isActive() {
    return this.state === 'TAS.ParticipantState.ACTIVE';
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-participant': TasParticipantModel;
  }
}
