import Model, { attr, belongsTo } from '@ember-data/model';
import type AggregationLoanModel from './aggregation-loan.ts';

export default class AggregationLoanForgivenessObservationModel extends Model {
  @attr declare at: string;
  @attr declare detail: Record<string, unknown>;
  @attr declare ineligible: number;
  @attr declare potential: number;
  @attr declare program: string;
  @attr declare provider: string;
  @attr declare qualified: number;
  @attr declare unqualified: number;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('aggregation-loan', {
    async: false,
    inverse: 'aggregationLoanForgivenessObservation',
  })
  declare aggregationLoan: AggregationLoanModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'aggregation-loan-forgiveness-observation': AggregationLoanForgivenessObservationModel;
  }
}
