import Model, { attr, belongsTo } from '@ember-data/model';
import type CommentableModel from './commentable.ts';
import type PersonModel from './person.ts';

export enum TasResponseTag {
  'TAS.ProgramApproval.APPROVE',
  'TAS.ProgramApproval.REJECT',
  'TAS.ProgramApproval.REVISE',
  'TAS.CourseApproval.APPROVE',
  'TAS.CourseApproval.REJECT',
  'TAS.CourseApproval.REVISE',
  'TAS.EvidenceApproval.APPROVE',
  'TAS.EvidenceApproval.REJECT',
  'TAS.EvidenceApproval.REVISE',
  'TAS.WithdrawComplete.APPROVE',
  'TAS.WithdrawComplete.REJECT',
  'TAS.WithdrawComplete.REVISE',
}

export const APPROVAL_RESPONSE_TYPES = new Map<keyof typeof TasResponseTag, string>([
  ['TAS.ProgramApproval.APPROVE', 'Approve Program'],
  ['TAS.ProgramApproval.REJECT', 'Reject Program'],
  ['TAS.ProgramApproval.REVISE', 'Revise Program'],
  ['TAS.CourseApproval.APPROVE', 'Approve Course'],
  ['TAS.CourseApproval.REJECT', 'Reject Course'],
  ['TAS.CourseApproval.REVISE', 'Revise Course'],
  ['TAS.EvidenceApproval.APPROVE', 'Approve Evidence'],
  ['TAS.EvidenceApproval.REJECT', 'Reject Evidence'],
  ['TAS.EvidenceApproval.REVISE', 'Revise Evidence'],
  ['TAS.WithdrawComplete.APPROVE', 'Approve Withdrawal'],
  ['TAS.WithdrawComplete.REJECT', 'Reject Withdrawal'],
  ['TAS.WithdrawComplete.REVISE', 'Revise Withdrawal'],
]);

export default class CommentModel extends Model {
  @attr declare comment: string;
  @attr declare commentor: string;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;
  @attr declare tags: string[];

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('commentable', {
    async: false,
    polymorphic: true,
    inverse: 'comments',
  })
  declare commentable: CommentableModel;
  @belongsTo('person', { async: false, inverse: null })
  declare person: PersonModel;

  // shorthand for whether this is a TAS approval comment
  get isTasApproval() {
    // this raises a glint warning when the tag isn't asserted to be a TasResponseTag
    // key, but a comment that's not related to TAS approval responses can definitely
    // have tags that aren't part of that set
    return this.tags.some((tag) => APPROVAL_RESPONSE_TYPES.has(<keyof typeof TasResponseTag>tag));
  }

  /*********************************************
   **  TAS Approval Canned Response Modeling  **
   *********************************************/

  // deserialize TAS approval comment from JSON
  get tasApprovalNote(): { title: string; body: string } {
    if (!this.isTasApproval) {
      throw new Error('Comment is not TAS approval response');
    }

    const note = this.comment ? JSON.parse(this.comment) : { title: '', body: '' };

    // feels more ergonomic to alias "this" here & preserve access to it in
    // scope for the inline getters/setters below, rather than use Object's
    // static defineProperty method (which would allow the use of arrow
    // to keep "this" in scope)
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const record = this;

    // this is what we get for modeling this all here, but at least it *is* all here
    return {
      get title(): string {
        return note.title;
      },
      set title(title: string) {
        record.comment = JSON.stringify({ ...note, title });
      },
      get body(): string {
        return note.body;
      },
      set body(body: string) {
        record.comment = JSON.stringify({ ...note, body });
      },
    };
  }

  // serialize TAS approval comment to JSON
  set tasApprovalNote(comment: { title: string; body: string }) {
    if (!this.isTasApproval) {
      throw new Error('Comment is not TAS approval response');
    }
    this.comment = JSON.stringify(comment);
  }

  get tasApprovalTag(): string {
    return this.tags.find((tag) => APPROVAL_RESPONSE_TYPES.has(<keyof typeof TasResponseTag>tag))!;
  }

  // set a tag, idempotently ensuring that only one TAS response type tag exists in
  // the record's tags list while ignoring any other ones
  set tasResponseTag(tag: string) {
    if (!APPROVAL_RESPONSE_TYPES.has(<keyof typeof TasResponseTag>tag)) {
      throw new Error('Tag is not valid TAS approval tag');
    }
    const otherTags = this.tags.filter((currentTag) => {
      return !APPROVAL_RESPONSE_TYPES.has(<keyof typeof TasResponseTag>currentTag);
    });
    this.tags = [...otherTags, tag];
  }

  // look up the friendly response type label by tag
  get tasResponseType() {
    if (!this.isTasApproval) {
      throw new Error('Comment is not TAS approval response');
    }

    return APPROVAL_RESPONSE_TYPES.get(<keyof typeof TasResponseTag>this.tasApprovalTag!);
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    comment: CommentModel;
  }
}
