import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from './user.ts';
import type EmployeeModel from './employee.ts';

export default class NotificationHistoryModel extends Model {
  @attr declare noteSubtype: string;
  @attr declare createdAt: string;
  @attr declare toEmail: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;
  @belongsTo('employee', { async: false, inverse: null })
  declare employee: EmployeeModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'notification-history': NotificationHistoryModel;
  }
}
