import Model, { attr, belongsTo } from '@ember-data/model';
import type TasProgramTemplateModel from './tas-program-template.ts';
import type TasGroupModel from './tas-group.ts';

export default class TasGroupingModel extends Model {
  @attr declare memberId: string;

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('tas-group', { async: false, inverse: 'tasGroup' }) declare tasGroup: TasGroupModel;
  @belongsTo('tas-program-template', { async: false, inverse: 'tasGroupings' })
  declare tasProgramTemplate: TasProgramTemplateModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-grouping': TasGroupingModel;
  }
}
