import Model, { hasMany } from '@ember-data/model';
import type ScheduleModel from './schedule.ts';

export default class OwnerModel extends Model {
  /*************************
   **  Relationships      **
   *************************/
  @hasMany('schedule', {
    async: false,
    inverse: 'owner',
    as: 'owner',
  })
  declare schedules: ScheduleModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    owner: OwnerModel;
  }
}
