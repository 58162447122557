import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type Intl from 'ember-intl/services/intl';

type Positional = [string | undefined];
type Named = {
  nestedPath?: string;
  defaultToBlank?: boolean;
};

export interface SafeTSignature {
  Args: {
    Positional: Positional;
    Named: Named;
  };
  Return: string | undefined;
}

/**
 * Returns a translation value if it exists, otherwise returns the raw translation key.
 * This more closely mimics how Vue-Intl works and is useful in cases where we are using
 * database values as translation keys. If a translation value doesn't exist for key `BACKPAY`
 * in the en-us translation file, this helper will return `BACKPAY` and not `Missing translation...`
 *
 * If you would prefer the default fallback to return a blank string instead of the translation key,
 * pass `defaultToBlank=true`. This will ensure that a blank string is returned if a translation does
 * not exist.
 *
 * {{safe-t "custom.delete" defaultToBlank=true}}
 *
 * If a translation value is nested, you can optionally pass `nestedPath` to indicate
 * where to find the translation. For example, the below code will search for a translation
 * value for `accounting.line-items.type.BACKPAY` before falling back to `BACKPAY` if none is found.
 *
 * {{safe-t "BACKPAY" nestedPath="accounting.line-items.type"}}
 *
 */
export default class SafeT extends Helper<SafeTSignature> {
  @service declare intl: Intl;

  compute([translationKeyString]: [string], { nestedPath = '', defaultToBlank = false }) {
    const prefix = nestedPath ? `${nestedPath}.` : '';

    const translationKey = `${prefix}${translationKeyString}`;

    if (this.intl.exists(translationKey, 'en-us')) {
      return this.intl.t(translationKey);
    }

    return defaultToBlank ? '' : translationKeyString;
  }
}
