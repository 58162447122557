import Model, { attr, hasMany } from '@ember-data/model';
import type LegacyAuthorizationResourceUserRelationshipTypeModel from './legacy-authorization-resource-user-relationship-type.ts';

export default class LegacyAuthorizationResourceEndpointModel extends Model {
  @attr declare description: string;
  @attr declare httpMethod: string;
  @attr declare policy: string;
  @attr declare service: Record<string, unknown>;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @hasMany('legacy-authorization-resource-user-relationship-type', {
    async: false,
    inverse: 'legacyAuthorizationResourceEndpoint',
  })
  declare legacyAuthorizationResourceUserRelationshipTypes: LegacyAuthorizationResourceUserRelationshipTypeModel[];
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'legacy-authorization-resource-endpoint': LegacyAuthorizationResourceEndpointModel;
  }
}
