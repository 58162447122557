import { createConsumer } from '@rails/actioncable';
import Service from '@ember/service';

// This service is used to connect to the ActionCable server for all connections. Should only
// be consumed by other cable services to ensure a single connection is maintained, and other
// services maintain subscriptions.

export default class CableService extends Service {
  consumer!: ReturnType<typeof createConsumer>;
  subscription: ReturnType<typeof createConsumer>['subscriptions']['create'] | null = null;

  async connect(apiHost: string, apiKey: string, token: string) {
    if (this.consumer) return;

    const KEY = encodeURIComponent(apiKey);
    const TOKEN = encodeURIComponent(token);
    const URL = `${apiHost}/cable?apiKey=${KEY}&token=${TOKEN}`;
    this.consumer = createConsumer(URL);
    this.consumer.connect();
  }
}
