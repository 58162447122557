import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

export interface EmberAWSQuicksightSignature {
  Element: HTMLElement;
  Args: {
    Positional: [string];
  };
}

export type EmberAWSQuicksightOptions = {
  height?: string;
  loadingHeight?: string;
  width?: string;
  parameters?: { Name: string; Values: string[] }[];
};

export default class EmberAWSQuicksight extends Modifier<EmberAWSQuicksightSignature> {
  // TODO: session context is imported from parent. Need to fix.
  @service declare sessionContext: unknown;

  modify(element: HTMLElement, [dashboardUrl]: [string], options = {}) {
    // @ts-expect-error: see todo above
    const userId = this.sessionContext.user?.id;

    if (!dashboardUrl || !userId) {
      return;
    }

    /**
      If `dashboardUrl` has changed and the element is still
      in the DOM, clear out the iframe so a new one can be
      embedded.
    */
    element.innerHTML = '';

    this.embedDashboard(element, dashboardUrl, userId, options);
  }

  async embedDashboard(
    element: HTMLElement,
    dashboardUrl: string,
    userId: string,
    options: EmberAWSQuicksightOptions = {}
  ) {
    const embeddingContext = await createEmbeddingContext();
    const { embedDashboard } = embeddingContext;

    const frameOptions = {
      url: dashboardUrl,
      container: element,
      height: options.height || options.loadingHeight || '700px', // 700px is default loading height before resize
      resizeHeightOnSizeChangedEvent: true,
      width: options.width || '100%',
      withIframePlaceholder: true,
    };
    const contentOptions = {
      parameters: [{ Name: 'userid', Values: [userId] }, ...(options.parameters || [])],
      locale: 'en-US',
    };

    const embeddedDashboardExperience = await embedDashboard(frameOptions, contentOptions);

    return embeddedDashboardExperience;
  }
}
