import type CompanyModel from './company.ts';
import Model, { attr, belongsTo } from '@ember-data/model';

type StatusHistory = {
  status: string;
  timestamp: string;
  user: string;
  updatedAt: string;
};

type PreviewCounts = {
  created: number;
  updated: number;
  unchanged: number;
  deactivated: number;
  reactivated: number;
};

type Counts = {
  loaded: number;
  created: number;
  updated: number;
  unchanged: number;
  deactivated: number;
  reactivated: number;
  createdSentEmail: number;
};

const canProcessStatuses = ['REQUIRES_USER_APPROVAL', 'REQUIRES_REVIEWER_APPROVAL'];

const canReprocessStatuses = [
  'REQUIRES_USER_APPROVAL',
  'REQUIRES_REVIEWER_APPROVAL',
  'FAILED_VALIDATION',
];

const abortedStatuses = [
  'ABORTED_BY_USER',
  'ABORTED_BY_REVIEWER',
  'ABORTED_BY_ADMIN',
  'ABORTED_FOR_STALENESS',
  'ABORTED_AS_DUPLICATE',
];

const pollingStatuses = [
  'WAITING_FOR_UPLOAD',
  'QUEUED',
  'VALIDATING_ELIGIBILITY_FILE',
  'EXECUTING_TASKS',
];

export default class EligibilityFileJobLogModel extends Model {
  @attr declare companyMeta: Record<string, unknown>[];
  @attr declare counts: Counts;
  @attr declare createdBy: string;
  @attr declare endTime: string;
  @attr declare fileName: string;
  @attr declare files: Record<string, unknown>;
  @attr declare jobErrors: Record<string, string>[];
  @attr declare plans: Record<string, unknown>[];
  @attr declare previewCounts: PreviewCounts;
  @attr declare startTime: string;
  @attr declare status: string;
  @attr declare statusHistory: StatusHistory[];
  @attr declare strategy: string;
  @attr declare updatedBy: number;
  @attr declare uploadUrl: string;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;
  @attr declare processId: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('company', { async: false, inverse: null })
  declare company: CompanyModel;

  /*************************
   **  Computed Properties**
   *************************/
  get abortedAt() {
    return this.statusHistory.find((status) => abortedStatuses.includes(status.status))?.updatedAt;
  }

  get isRunning() {
    return this.statusHistory.some((update) => update.status === 'EXECUTING_TASKS');
  }

  get isUploading() {
    return this.status === 'WAITING_FOR_UPLOAD';
  }

  get canProcess() {
    return canProcessStatuses.includes(this.status);
  }

  get canReprocess() {
    return canReprocessStatuses.includes(this.status);
  }

  get canSchedule() {
    return this.status === 'REQUIRES_REVIEWER_APPROVAL' || this.status === 'REQUIRES_USER_APPROVAL';
  }

  get canAbort() {
    const additionalStates = ['ERROR_REPORTED', 'FAILED_VALIDATION', 'COMPLETED'];

    return !abortedStatuses.includes(this.status) && !additionalStates.includes(this.status);
  }

  get hasAborted() {
    return abortedStatuses.includes(this.status);
  }

  get totalProcessed() {
    const { previewCounts, counts } = this;

    const preview =
      previewCounts.created +
      previewCounts.updated +
      previewCounts.deactivated +
      previewCounts.reactivated;

    const processed = counts.created + counts.updated + counts.deactivated + counts.reactivated;

    const total = Math.floor((processed / preview) * 100);

    return `${isNaN(total) ? 0 : total}%`;
  }

  get totalUnchanged() {
    const { previewCounts } = this;

    return previewCounts.unchanged;
  }

  get autoProcessEnabled() {
    // This makes a huge assumption that the company relation is loaded
    if (!this.company.companySetting) {
      return true;
    }
    return this.company.companySetting.eligibilityDefault['auto-process'];
  }

  #interval?: ReturnType<typeof setInterval>;

  async startPollingForStatus() {
    this.#interval = setInterval(() => {
      if (pollingStatuses.includes(this.status)) {
        this.reload();
      } else {
        console.log('Skip Remote Polling');
        if (this.status === 'COMPLETED') {
          clearInterval(this.#interval);
        }
      }
    }, 3000);
  }

  stopPollingForStatus() {
    if (this.#interval) {
      clearInterval(this.#interval);
    }
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'eligibility-file-job-log': EligibilityFileJobLogModel;
  }
}
