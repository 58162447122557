import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountModel from './account.ts';
import type CompanyModel from './company.ts';
import type FinancialInstitutionModel from './financial-institution.ts';
import type InvoiceLineItemModel from './invoice-line-item.ts';
import type LoanModel from './loan.ts';
import type UserModel from './user.ts';

export default class PaymentTransactionModel extends Model {
  @attr declare amount: number;
  @attr declare checkNumber: number;
  @attr declare companyContribution: number;
  @attr declare currentBalance: number;
  @attr declare endToEndId: string;
  @attr declare instructionId: string;
  @attr declare interestRate: number;
  @attr declare isActive: boolean;
  @attr declare isCredit: boolean;
  @attr declare minMonthlyPayment: number;
  @attr declare paymentAch: string;
  @attr declare transactionDate: string;
  @attr declare transactionDescription: string;
  @attr declare transactionStatusType: string;
  @attr declare userContribution: number;
  @attr declare financialInstitutionName: string;

  @attr declare createdAt: string;
  @attr declare createdBy: string;
  @attr declare updatedAt: string;
  @attr declare updatedBy: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null }) declare user: UserModel;
  @belongsTo('loan', { async: false, inverse: null }) declare loan: LoanModel;
  @belongsTo('invoice-line-item', { async: false, inverse: null })
  declare invoiceLineItem: InvoiceLineItemModel;
  @belongsTo('company', { async: false, inverse: null })
  declare company: CompanyModel;
  @belongsTo('financial-institution', { async: false, inverse: null })
  declare financialInstitution: FinancialInstitutionModel;
  @belongsTo('account', { async: false, inverse: null })
  declare account: AccountModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-transaction': PaymentTransactionModel;
  }
}
