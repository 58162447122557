const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

/**
 * Transforms `text` to one of the colors specified in `colors`.
 *
 * @param text   - The text to transform
 * @param colors - An array of colors to choose from
 *
 * @returns A color from `colors` derived from `text`
 */
export function transformToColor(text: string, colors: string[]): string {
  // Adapted from `Avatar.vue` to attempt to match the same coloring algorithm without so much code
  const position = Math.round(
    Array.from(text).reduce(
      (index: number, letter: string) => index + alphabet.indexOf(letter.toUpperCase()) + 1,
      0
    ) / text.length
  );
  const index = Math.round(((colors.length - 1) * position) / alphabet.length);

  return colors[Math.abs(index) % colors.length] as string;
}

export default null; // silence a false warning
