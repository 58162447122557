import { helper } from '@ember/component/helper';

// eslint-disable-next-line no-empty-pattern
export default helper(function formatDateString([dateString]: [string], {}) {
  if (!dateString) {
    return null;
  }

  const parts = dateString.split('-');
  const transformedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
  return transformedDate;
});
