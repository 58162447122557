import Model, { attr, hasMany } from '@ember-data/model';
import { isBlank } from '@ember/utils';
import { set } from '@ember/object';
import type AttachmentModel from './attachment.ts';

interface DownloadUrlsSignature {
  original: string;
}

export default class DigitalAsset extends Model {
  @attr declare bucket: string;
  @attr declare contentType: string;
  @attr declare downloadUrls: DownloadUrlsSignature;
  @attr declare filename: string;
  @attr declare fullPath: string;
  @attr declare raw: Record<string, unknown>;
  @attr declare name: string;
  @attr declare path: string;
  @attr declare size: number;
  @attr declare slug: string;
  @attr declare tags: Record<string, unknown>;
  @attr declare uuid: string;
  @attr declare signedUrl: string;
  @attr declare classification: string;
  @attr declare description: string;

  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships       **
   *************************/

  @hasMany('attachment', { async: false, inverse: null })
  declare attachments: AttachmentModel[];

  /*************************
   **  Computed Properties **
   *************************/

  get presentableName() {
    const { name, filename } = this;

    return isBlank(name) ? filename : name;
  }

  set presentableName(value) {
    set(this, 'name', value);
  }

  // TODO: convert
  get assetUrl() {
    return this.downloadUrls.original;
  }

  get assetSrcOriginal() {
    return this.downloadUrls.original;
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'digital-asset': DigitalAsset;
  }
}
