const gradeOrder = ['A', 'B', 'C', 'D', 'F', 'Pass', 'Fail', 'Incomplete', 'Withdrawal'];

export const sortGradesByOrder = (array: string[]): string[] => {
  return array.sort((a, b) => {
    const indexA = gradeOrder.indexOf(a);
    const indexB = gradeOrder.indexOf(b);

    if (indexA === -1 && indexB === -1) {
      // If both elements are not in the gradeOrder, use default localeCompare
      return a.localeCompare(b);
    } else if (indexA === -1) {
      // If only a is not in gradeOrder, move it to the end
      return 1;
    } else if (indexB === -1) {
      // If only b is not in gradeOrder, move it to the end
      return -1;
    } else {
      // If both elements are in gradeOrder, sort based on their order in gradeOrder
      return indexA - indexB;
    }
  });
};

export default null; // silence a false warning
