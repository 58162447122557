import type Employee from '../../models/employee.ts';
import type Person from '../../models/person.ts';
import type Agent from '../../models/agent.ts';

const getInitial = (name: string) => (name?.[0] ?? '').toUpperCase();

/**
 * Returns the first and last initials for the `model` with the properties
 * `firstName` and `lastName`. The initials are returned uppercase.
 *
 * @param {Model} model A model that has `firstName` and `lastName` properties
 *
 * @return {string} The initials for the model
 */
const getInitials = (model: Person | Employee | Agent) =>
  `${getInitial(model.firstName)}${getInitial(model.lastName)}`;

export { getInitials };

export default null; // silence a false warning
